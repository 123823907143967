import React, { useEffect, useRef, useState } from "react";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from "../../Services/ActionButton";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditTicket, {
  AgentAssignTicket,
  AttachTicket,
} from "./AddEditTicket";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

const ManageTickets = () => {
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});
  const [records, setRecords] = useState([]);
  PageInfo({ pageTitle: "Manage Tickets" });
  useEffect(() => {
    SetPermission({
      ManageAdd: true, // ActionPermission("Asset Type - Add"),
      ManageEdit: true, //  ActionPermission("Asset Type - Edit"),
      ManageDelete: true, // ActionPermission("Asset Type - Delete"),
    });
  }, [permissionList]);

  const refSnackbar = useRef();
  const ref = useRef();
  const MasterPageName = "Manage Tickets";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const addEditModalRef = useRef();
  const agentModalRef = useRef();

  const AddButtonText = "Ticket";
  const dispatch = useDispatch();
  const [deletedName, setDeletedName] = useState(null);
  const onDelete = async (Details) => {
    const data = Details.split(",");
    const TicketId = data[0];
    setDeletedName(data[1]);
    await WebService({
      endPoint: `CommonUtility/tickets?TicketId=${TicketId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchAllTickets();
  };

  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? []);
  const UserId = useSelector((s) => s.auth.LoggedCustomer ?? []);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  const isCompany = Roles?.some(
    (element) => element === StandardConst.SystemRole.Company
  );
  const isEmployee = Roles?.some(
    (element) => element === StandardConst.SystemRole.Employee
  );
  const fetchAllTickets = async () => {
    let endPoint = ``;
    if (isCompany) {
      // endPoint = `CommonUtility/fetchAll/tickets?CompanyId=${companyInfo.CompanyId}`;
      endPoint = `tickets/AllTickets?CompanyId=${companyInfo.CompanyId}`;
    }
    if (isEmployee) {
      const data = await WebService({
        endPoint: `CommonUtility/Edit/agents?EmployeeId=${EmployeeId || 0}`,
        dispatch,
      }).then((c) => (c.length > 0 ? c[0] : {}));
      if (data.AgentId) {
        endPoint = `tickets/fetchByUserIdOrEmployeeIdTickets?AgentId=${data.AgentId}&CreateByUserIdOrEmployeeId=${EmployeeId}&CreateByUserType=${StandardConst.UserType.Employee}`;
      }
    } else if (UserId) {
      const data = await WebService({
        endPoint: `CommonUtility/Edit/agents?UserId=${UserId || 0}`,
        dispatch,
      }).then((c) => (c.length > 0 ? c[0] : {}));

      if (data.AgentId) {
        endPoint = `tickets/fetchByUserIdOrEmployeeIdTickets?AgentId=${data.AgentId}&CreateByUserIdOrEmployeeId=${UserId}&CreateByUserType=${StandardConst.UserType.User}`;
      }
    }
    const data = await WebService({
      endPoint: endPoint,
      dispatch,
    });
    const modifiedData = await Promise.all(
      data.map(async (item) => {
        if (item.Status) {
          const foundStatus = StandardConst.TicketStatusDropdown?.find(
            (obj) => obj?.value === item?.Status
          );
          item.Status = foundStatus?.text;
        }
        if (item.Priority) {
          const foundPriority = StandardConst.TicketPriorityDropdown?.find(
            (obj) => obj.value === item.Priority
          );
          item.Priority = foundPriority?.text;
        }

        let AssigneeFullName;
        let AssigneeImage;
        if (item.EmpName) {
          AssigneeFullName = item.EmpName;
          AssigneeImage = item.EmpImage;
        } else if (item.UserName) {
          AssigneeFullName = item.UserName;
          AssigneeImage = item.UserImage;
        } else {
          AssigneeFullName = "";
          AssigneeImage = "";
        }
        item.AssigneeFullName = AssigneeFullName;
        item.AssigneeImage = AssigneeImage;

        if (
          item.CreateByUserType &&
          item.CreateByUserType === StandardConst.UserType.Employee
        ) {
          const EmpData = await WebService({
            endPoint: `CommonUtility/Edit/employees?EmployeeId=${
              item.CreateByUserIdOrEmployeeId || 0
            }`,
            dispatch,
          }).then((c) => (c.length > 0 ? c[0] : {}));
          item.CreateByFullName = EmpData.FullName;
          item.CreateByImage = EmpData.ProfileImage;
        }

        if (
          item.CreateByUserType &&
          item.CreateByUserType === StandardConst.UserType.User
        ) {
          const UsersData = await WebService({
            endPoint: `CommonUtility/Edit/users?UserId=${
              item.CreateByUserIdOrEmployeeId || 0
            }`,
            dispatch,
          }).then((c) => (c.length > 0 ? c[0] : {}));
          item.CreateByFullName = UsersData.FullName;
          item.CreateByImage = UsersData.ProfileImage;
        }

        return item;
      })
    );
    setRecords(modifiedData);
    dispatch(ConfigurationflowStatusUpdate());
  };

  useEffect(() => {
    fetchAllTickets();
  }, [companyInfo.CompanyId, EmployeeId, UserId, Roles]);

  const [bData] = React.useState([
    {
      title: "Helpdesk",
      hrefLink: "#",
    },
    {
      title: "Manage Tickets",
      hrefLink: "#",
    },
  ]);
  const navigate = useNavigate();

  const columns = [
    {
      Text: "Priority",
      Value: "Priority",
      style: { borderRight: "none" },
      render: (dr) => {
        return <></>;
      },
    },
    {
      Text: "Title",
      Value: "Title",
      style: { borderRight: "none", borderLeft: "none" },
      render: (dr) => {
        return (
          <>
            <h6
              className="font-weight-bold"
              onClick={() =>
                navigate("/ViewTicket", { state: { TicketId: dr.TicketId } })
              }
              style={{ cursor: "pointer" }}
            >
              {dr.Title}
            </h6>
            <div style={{ width: "225%" }}>{dr.Description}</div> <br />
            <div className="assignee-details d-flex gap-5 justify-content-center align-items-center">
              <Chip
                label={dr.Priority}
                color={
                  dr.Priority === StandardConst.TicketPriorityDropdown[2].text
                    ? "error"
                    : dr.Priority ===
                      StandardConst.TicketPriorityDropdown[1].text
                    ? "warning"
                    : "default"
                }
                variant="filled"
                sx={{ borderRadius: "3px" }}
              />
              <div className="d-flex gap-2">
                <span className="d-flex">
                  {dr.CreateByImage ? (
                    <Avatar
                      alt={dr.CreateByFullName.charAt(0)}
                      src={`${StandardConst.apiBaseUrl}/uploads/${dr.CreateByImage}`}
                    />
                  ) : dr.CreateByFullName ? (
                    <Avatar alt={dr.CreateByFullName.charAt(0)}>
                      {dr.CreateByFullName.charAt(0)}
                    </Avatar>
                  ) : (
                    ""
                  )}
                </span>

                <div className="FName">
                  {dr.CreateByFullName}
                  <div className="text-muted d-flex text-nowrap">
                    {dr.CreationDate &&
                      DateTime.fromISO(dr.CreationDate, {
                        zone: "utc",
                      }).toFormat("dd-MM-yyyy hh:mm a")}
                  </div>
                </div>
              </div>

              <div>
                <div className="assignee-details d-flex">
                  <div className="me-2">
                    {dr.AssigneeImage ? (
                      <Avatar
                        alt={dr.AssigneeFullName.charAt(0)}
                        src={`${StandardConst.apiBaseUrl}/uploads/${dr.AssigneeImage}`}
                      />
                    ) : dr.AssigneeFullName ? (
                      <Avatar alt={dr.AssigneeFullName.charAt(0)}>
                        {dr.AssigneeFullName.charAt(0)}
                      </Avatar>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="FName d-flex justify-content-center align-items-center">
                    {dr.AssigneeFullName}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      Text: "Description",
      Value: "Description",
      style: { borderRight: "none", borderLeft: "none" },
      render: () => {
        return <></>;
      },
    },
    {
      Text: "Create By",
      Value: "CreateBy",
      style: { borderRight: "none", borderLeft: "none" },
      render: (dr) => {
        return <></>;
      },
    },
    {
      Text: "Status",
      Value: "Status",
      style: { borderRight: "none", borderLeft: "none" },
      render: (dr) => {
        return <></>;
      },
    },
    {
      Text: "Agent",
      Value: "AssigneeFullName",
      style: {
        borderRight: "none",
        borderLeft: "none",
        verticalAlign: "bottom",
      },
      render: (dr) => {
        return <></>;
      },
    },
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      style: { borderLeft: "none" },
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          <div className="ticket-action-buttons">
            <div className="d-flex">
              <ActionButton
                onClick={() => fnEdit(dr.TicketId)}
                disabled={!permission.ManageEdit}
                IconName="Edit"
                IconTooltip={"Edit Ticket"}
                id={`btnEdit_${dr.TicketId}`}
              />
              <ActionButton
                onClick={() => AgentModel(dr.TicketId)}
                disabled={!permission.ManageEdit}
                IconName="Assign"
                IconTooltip={"Assign Agent"}
                id={`btnAssetTypeEdit_${dr.TicketId}`}
              />
              <ActionButton
                onClick={() => AttachModel(dr.TicketId)}
                disabled={!permission.ManageEdit}
                IconName="Coordinator"
                IconTooltip={"Attach Ticket"}
                id={`btnAssetTypeEdit_${dr.TicketId}`}
              />
              <ActionButton
                onClick={(e) =>
                  ref.current.confirmAlert(
                    "Delete", //Confirm button text
                    "Are You Sure", // Text if Alert
                    "Do you want to delete " + dr.Title, // Message of Alert
                    `${dr.TicketId},${dr.Title}` // Endpoint to hit for delete
                  )
                }
                disabled={!permission.ManageDelete}
                IconName="Delete"
                IconTooltip={"Delete Ticket"}
                id={`btnAssetTypeDelete_${dr.TicketId}`}
              />
            </div>
            <div
              className="ticket-status"
              style={{ float: "right", paddingRight: "5px" }}
            >
              <Chip
                label={dr.Status}
                color={
                  dr.Status === StandardConst.TicketStatusDropdown[0].text
                    ? "default"
                    : dr.Status === StandardConst.TicketStatusDropdown[1].text
                    ? "warning"
                    : "success"
                }
                variant="filled"
                sx={{ borderRadius: "3px" }}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  const AttachModelRef = useRef();
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const AgentModel = async (id) =>
    await agentModalRef.current.openModal(id || 0);
  const AttachModel = async (id) =>
    await AttachModelRef.current.openModal(id || 0);

  return (
    <>
      <SnackbarComponent
        ref={refSnackbar}
        confirmMessage={`Ticket ${
          deletedName !== null ? deletedName : ""
        } Deleted successfully`}
      />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />

      <StaticListComponent
        ExcluedFieldFromColumns={["Create By"]}
        ExcelExportFieldNames={[
          {
            ExcelColumnHeading: "Create By",
            DataColumnName: "CreateByFullName",
          },
        ]}
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        tableCss={"table-row-card-layout"}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageTicketsheaderValueNoResults}
            subHeaderValue={StandardConst.ManageTicketsSubHeader}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button
                    variant="outline-primary"
                    onClick={() => fnEdit(0)}
                    id={`no_record_${AddButtonText}`}
                  >
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={false}
      ></StaticListComponent>
      <AddEditTicket
        callBackEvent={async () => await fetchAllTickets()}
        ref={addEditModalRef}
      ></AddEditTicket>
      <AgentAssignTicket
        callBackEvent={async () => await fetchAllTickets()}
        ref={agentModalRef}
      ></AgentAssignTicket>
      <AttachTicket
        callBackEvent={async () => await fetchAllTickets()}
        ref={AttachModelRef}
      ></AttachTicket>
    </>
  );
};

export default ManageTickets;
