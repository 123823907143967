import "./App.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "./Component/Layout/HeaderComponent";
import SideBarComponent from "./Component/Layout/SideBarComponent";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "./Services/WebService";
import {
  setPermission,
  setMenu,
  setAssignRole,
  setCompanyInfo,
  setLoggedUser,
  setChildUsers,
  setLoggedCustomer,
  setUserType
} from "./Services/authSlice";
import _, { where } from "underscore";
import Cookies from "universal-cookie";
import { ConfigurationFlow } from "./Services/ConfigurationFlow";
import { memo } from "react";
import { WiseOfficeDayHeader } from "./Component/Dashboard/Company/CompanyDashboard";
import { useStepContext } from "@mui/material";
const App = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const renderAfterCalled = useRef(false);
 
  const menu = useSelector((state) => state?.auth?.MenuList ?? []);
  const loggedCustomer=useSelector(s=>s.auth.LoggedCustomer??0);
  useSelector(async (state) => {
    if (!state.auth.loged) {
      if (loggedCustomer === 0)
        navigate(`/Login?company=${(cookies.get("company") ?? {}).Code}`);
      else
        navigate(`/User/Login?company=${(cookies.get("company") ?? {}).Code}`);
    }
  });
  const init = () => {
    WebService({ dispatch, endPoint: "User/Permission" }).then((res) => {
      dispatch(setMenu(res.Menu));
      dispatch(setPermission(res.Permission));
      dispatch(setAssignRole(res.Role));
      //UrlCheck(res.Menu);
    });
    dispatch(setCompanyInfo(cookies.get("company") ?? {}));
    WebService({ dispatch, endPoint: "User/LoggedUser" }).then((r) => {
      dispatch(setLoggedUser(r.EmployeeId));
     
      dispatch(setLoggedCustomer(r.UserId));
      dispatch(setUserType(r.UserType))
      if((r.EmployeeId??0)!==0){
        WebService({ dispatch, endPoint: `User/ChildUsers/${r.EmployeeId}` }).then((cu) => {
          dispatch(setChildUsers(cu));
        });
      }
    });
  };
  useEffect(() => {
    //if(renderAfterCalled.current)
    // UrlCheck(menu);
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    if (!renderAfterCalled.current) {
      Promise.all([init()]);
    }
    renderAfterCalled.current = true;
  }, []);
  const UrlCheck = (menu) => {
    if (location.pathname !== "/Dashboard" && location.pathname.indexOf("/Common") < 0) {
      if (
        where(menu ?? [], { Route: location.pathname.substring(1) }).length < 1
      )
      navigate("/Dashboard");
    }
  };



  return (
    <>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary page-background">
        <SideBarComponent />
        <div className="w-100">
          <div className="px-2">
            <WiseOfficeDayHeader />
          </div>
          <Outlet />
        </div>
      </div>
      <ConfigurationFlow />
    </>
  );
};

export default memo(App);
