import React, { forwardRef } from "react";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import {
  Alert,
  Avatar,
  Chip,
  styled,
} from "@mui/material";
import Button from "react-bootstrap/esm/Button";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import {
  WSErrorAlert,
  WSSuccessAlert,
  WSWarningAlert,
} from "../../Services/WSAlert";
import { useState } from "react";

import {
  CheckCircleOutline,
  Done,

} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { useEffect } from "react";

const checkMark = <CheckCircleOutline sx={{ fontSize: 30 }} />;
const incompleteCheckMark = (
  <Avatar sx={{ width: 30, height: 30 }}>{checkMark}</Avatar>
);
const completeCheckMark = (
  <Avatar sx={{ bgcolor: green[500], width: 30, height: 30 }}>
    {checkMark}
  </Avatar>
);

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 5,
  padding: theme.spacing(2, 0.8, 2, 0.8),
  "& .MuiChip-root": {},
}));
const CompletedButton = () => {
  return(
     <>
    <StyledChip
      icon={<Done />}
      label="Completed"
      color="success"
      size="small"
    />
  </>
  )
  
}


const ExitEmployee = forwardRef(({ EmployeeInfo, setAction }, ref) => {
  const dispatch = useDispatch();
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [dateLable, setdateLable] = useState(false);
  const [pendingList, setpendingList] = useState([]);
  const [gettinglist, setgettinglist] = useState(false);
  const getpedingList = async () => {
    setgettinglist(true);
    const data = await WebService({
      endPoint: `ExitEmployee/getpedingItemList`,
      methods: "post",
      body: { EmployeeId: EmployeeInfo.EmployeeId },
    })
      .then((res) => {
        setgettinglist(false);
        setpendingList(res.pedinglist);
      })
      .catch((error) => {
        WSErrorAlert("error occured", error);
      });
  };
  useEffect(() => {
    getpedingList();
  }, []);

  const MakeEmployeeInactive = async (ExitData) => {
    for (const item of pendingList) {
      if (item.count !== 0 && pendingList.length > 0) {
        WSErrorAlert("First Complete The Pending List", "");
        return false; // Form should not be submitted
      }
    }
    if (!ExitData.DOE) {
      WSWarningAlert("Please fill the exit date");
      return;
    }

    if (!ExitData.ExitDesc) {
      WSWarningAlert("Please fill the exit description");
      return;
    }

    ExitData.EmployeeId = EmployeeInfo.EmployeeId;
    ExitData.CompanyId = companyInfo.CompanyId;
    const data = await WebService({
      endPoint: `ExitEmployee/`,
      body: ExitData,
      methods: "post",
      dispatch,
    });
    if (data.status) {
      WSSuccessAlert(
        "Employee Exit Process Complete",
        "The employee has been marked as INACTIVE. They can no longer access the system."
      );
    } else {
      WSErrorAlert("Error occured", "something went wrong");
    }
  };

  return (
    <div ref={ref}>
      {/* Card Header */}

      {/* Card Body */}
      <Form onSubmit={MakeEmployeeInactive}>
        <div className=" card ">
          <div className="card-header p-0">
            <Alert
              severity="info"
              className=" w-100 p-2 m-0 font-semibold rounded-top-3"
            >
              Employee Exit
            </Alert>
          </div>
          {/* Exit Reason */}
          <span className="p-2">
            <div className="row">
              <div className="col-md-12">
                <FormInputDropdown
                  name="ReasonForExit"
                  ddOpt={[
                    { value: "Resigned", text: "Resignation" },
                    { value: "Ternimated", text: "Termination" },
                  ]}
                  label="Choose Exit Reason"
                  setValue={(v) => setdateLable(v)}
                />
              </div>
            </div>

            {/* Exit Date */}
            {dateLable && (
              <div className="col-md-12">
                <FormInputText
                  label={`${dateLable} Date`}
                  name="DOE"
                  type="date"
                  isRequired="true"
                  portalId="root"
                />
              </div>
            )}

            {/* Exit Description */}
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Exit Description"
                  name="ExitDesc"
                  as="textarea"
                  isRequired="true"
                  autoComplete="off"
                />
              </div>
            </div>
          </span>

            {/* Pending Items List */}
            <div className="mt-3">
            <div className=" p-0">
            <Alert
              severity="info"
              className=" w-100 p-2 m-0 font-semibold "
            >
            Pending Item List
            </Alert>
          </div>
              <hr className="mt-0" />
              {gettinglist ? (
                <div className="items-center m-2">
                  ...Getting Items List
                </div>
              ) : pendingList.length > 0 ? (
                <div>
                  
                     
                      <div className="p-3 ">
                        {pendingList.map((item, index) => (
                          <div
                            className={`row ${
                              pendingList.length - 1 !== index ? "mb-2" : ""
                            }`}
                            key={index}
                          >
                            <div className="col flex-row d-flex justify-content-start">
                              {/* Icon indicating completion or pending */}
                              {item.count > 0
                                ? incompleteCheckMark
                                : completeCheckMark}
                              <span className="mx-2 font" style={{fontFamily:"inherit"}}>
                                {index + 1}. {item.category}
                              </span>
                              <Chip
                                label={
                                  item.count > 0
                                    ? `${item.count} pending`
                                    : "Completed"
                                }
                                variant="outlined"
                                size="small"
                                color={item.count > 0 ? "error" : "success"}
                              />
                            </div>
                            <div className="col d-flex justify-content-end">
                              {/* Conditionally render a button or message */}
                              {item.count > 0 ? (
                                <button
                                  onClick={() => {
                                    setAction(item.action)
                                    
                                  }}
                                  type="button"
                                  className="btn btn-outline-primary btn-sm"
                                >
                                  Complete Now
                                </button>
                              ) : (

                             <CompletedButton/>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    
                 
                </div>
              ) : (
                <p className="text-center text-gray-500">No pending items.</p>
              )}

           
            </div>
          
          
            <div className=" m-2 ">
            <Button
              variant="outline-primary"
              type="submit"
              id="btnSubmit"
              className="px-4 float-end"
            >
              Save Info and Make Employee Inactive
            </Button>
            </div>
       
        </div>
      </Form>
    </div>
  );
});
export default ExitEmployee;
