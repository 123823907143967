import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { StandardConst } from '../../Services/StandardConst';
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import { useDispatch, useSelector } from "react-redux"
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import CreateEvent from './CreateEvent';
import Button from "react-bootstrap/Button";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import Modal from "react-bootstrap/Modal";
import { Alert, Container, Box, Stack } from "@mui/material";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';
import { chain } from 'underscore';
import { DateTime } from "luxon";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { DateRange } from "../Dashboard/Company/CompanyDashboard";
import ModalManageVisitorType from './ModalManageVisitorType';

const ManageEvents = () => {

    const refSnackbar = useRef();
    const ref = useRef();
    const refModalManageVisitorType = useRef();
    const MasterPageName = "Manage Events";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Event";
    const dispatch = useDispatch();
    PageInfo({ pageTitle: "Manage Events" });
    const GenerateQRRef = useRef();
    const addEditModalRef = useRef();
    const [records, setRecords] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(state?.openModal){
            fnEdit(0);
        }
    }, [state]);

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [condition, setCondition] = useState({});
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Event - Add"),
            ManageEdit: ActionPermission("Event - Edit"),
            ManageDelete: ActionPermission("Event - Delete"),
            ManageQRCode: ActionPermission("Event - View and Download QR Code"),
            ManageCoordinator: ActionPermission("Event - Add Co-ordinator"),
            ManageAttributes: ActionPermission("Event - Manage Attributes"),
            ManageVisitor: ActionPermission("Event - Manage Visitor"),
        });
    }, [permissionList]);

    const [bData,] = React.useState([
        {
            title: "Visitor Management",
            hrefLink: "#",
        },
        {
            title: "Manage Events",
            hrefLink: "#",
        },
    ]);
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});

    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    var userType = "";
    var ShowAllEventRecord = false;
    var EmployeeOrUserId;

    if(EmployeeId){
        userType = StandardConst.UserType.Employee;
        EmployeeOrUserId = EmployeeId
    }else if(UserId){
        userType = StandardConst.UserType.User;
        EmployeeOrUserId = UserId;
    }
    if(Roles?.some(element => element === StandardConst.SystemRole.Company) || Roles?.some(element => element === StandardConst.SystemRole.Receptionist) || Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)){
        ShowAllEventRecord = true;
        if(Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)) {
            userType = StandardConst.UserType.SuperAdmin;
        }
    }else if(Roles?.some(element => element === StandardConst.SystemRole.EventCoordinator)){
        ShowAllEventRecord = false;
    }

    const fetchManageEvents = async (filters) => {
        console.log(filters);
    
        // Validate and assign FromDate and ToDate from filters
        const { FromDate, ToDate } = filters ?? {};
    
        // Parse and adjust the dates if they exist
        const originalEndDate = ToDate ? DateTime.fromISO(ToDate) : null;
        const originalStartDate = FromDate ? DateTime.fromISO(FromDate) : null;
    
        // Increment the end date by 1 day to include the full range
        const adjustedEndDate = originalEndDate ? originalEndDate.plus({ days: 1 }).toISO() : null;
    
        // Build the endpoint URL
        const endPoint = `Event/DateWiseEventList?CompanyId=${StandardConst.YesNo.Yes}&EventToDate=${adjustedEndDate}&EventFromDate=${originalStartDate?.toISO()}&ShowAllEventRecord=${ShowAllEventRecord}&userType=${userType}&EmployeeOrUserId=${EmployeeOrUserId}`;
    
        // Call the WebService
        await WebService({
            endPoint: endPoint,
            dispatch,
        })
            .then((response) =>
                chain(response ?? [])
                    .map((event) => ({
                        ...event,
                        VisitorUrl: `${window.location.origin}/#/Visitor/Self/CheckIn?company=${CompanyInfo.Code}&e=${window.btoa(event.EventId)}`,
                    }))
                    .value()
            )
            .then((updatedRecords) => setRecords(updatedRecords));
    
        // Dispatch an update to the flow status
        dispatch(ConfigurationflowStatusUpdate());
    };
    

    useEffect(() => {
        if(condition.StartDate !== undefined){
            fetchManageEvents();
        }
    }, [CompanyInfo, condition.StartDate, ShowAllEventRecord, userType]);


    const onDelete = async (EventId) => {
        await WebService({
            endPoint: `CommonUtility/events?EventId=${EventId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchManageEvents();
    };


    const renderDurationComponent = (dr) => {
        const fromDateTime = DateTime.fromFormat(dr.EventFromDate + ' ' + dr.EventFromTime, 'dd-MM-yyyy HH:mm:ss');
        const toDateTime = DateTime.fromFormat(dr.EventToDate + ' ' + dr.EventToTime, 'dd-MM-yyyy HH:mm:ss');
        const duration = toDateTime.diff(fromDateTime);
        const { hours, minutes, seconds } = duration.shiftTo('hours', 'minutes', 'seconds');
        return (
            <div>
                {hours}{":"}{minutes}
            </div>
        );
    };

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);


    const columns = [
        {
            Text: "Event Name",
            Value: "EventName",
        },
        {
            Text: "Place",
            Value: "EventPlace",
        },
        {
            Text: "From Date",
            DateFormat: "dd-MM-yyyy",
            Value: "EventFromDate",
            render: (dr) => (
                <>
                    <span>
                        {dr.EventFromDate}
                        {" "}
                        {
                        dr.EventTime !== StandardConst.EventTime[0].value ?  DateTime.fromISO(dr.EventFromTime).toFormat("hh:mm a")
                        : null
                        }
                    </span>
                </>
            )
        },
        {
            Text: "To Date",
            DateFormat: "dd-MM-yyyy",
            Value: "EventToDate",
            render: (dr) => (
                <>
                    <span>
                        {dr.EventToDate}
                        {' '}
                        {
                        dr.EventTime !== StandardConst.EventTime[0].value ?  DateTime.fromISO(dr.EventToTime).toFormat("hh:mm a")
                        : null
                        }
                    </span>
                </>
            )
        },
        {
            Text: "Duration",
            Value: "EventTime",
            render: (dr) => (
                <>
                    {dr.EventTime === StandardConst.EventTime[0].value ? (StandardConst.EventTime[0].text) :
                            renderDurationComponent(dr)
                    }
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            // isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <span className='d-flex'>

                    <ActionButton
                        onClick={async () => await refModalManageVisitorType.current.openModal(dr.EventId)}
                        // disabled={!permission.ManageEdit}
                        IconName="Badge"
                        IconTooltip={"Manage badge templetes"}
                        id={`ManageBadgeTempletes_${dr.EventId}`}
                    />
                    <ActionButton
                        onClick={() => navigate("/Visitor/Registration", { state: { EventId : dr.EventId } })}
                        disabled={!permission.ManageEdit}
                        IconName="EventRegister"
                        IconTooltip={"Register Event Visitor"}
                        id={`RegisterEventVisitor_${dr.EventId}`}
                    />
                    <ActionButton
                        onClick={() => fnEdit(dr.EventId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Event"}
                        id={`btnEventsEdit_${dr.EventId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.EventName, // Message of Alert
                                dr.EventId  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Event"}
                        id={`btnEventsDelete_${dr.EventId}`}
                    />
                    <ActionButton
                        onClick={async () => await GenerateQRRef.current.openModal(dr.VisitorUrl, dr.EventLogo)}
                        IconName="QR"
                        IconTooltip={"QR Code"}
                        id="btnEventDownload"
                        disabled={!permission.ManageQRCode}
                    />
                    <ActionButton
                        IconTooltip={"Add Co-ordinator"}
                        IconName="Coordinator"
                        id="btnEventCoordinator"
                        onClick={() => { navigate("/ManageEventCoordinators", { state: { EventId: dr.EventId } }) }}
                        disabled={!permission.ManageCoordinator}
                    />
                    <ActionButton
                        IconTooltip={"Events Attributes"}
                        IconName="Attribute"
                        id="btnEventAttribute"
                        onClick={() => { navigate("/ManageEventAttributes", { state: { EventId: dr.EventId } }) }}
                        disabled={!permission.ManageAttributes}
                    />
                    <ActionButton
                        IconTooltip={"Event Visitors"}
                        IconName="EventVisitors"
                        id="btnEventAttribute"
                        onClick={() => { navigate("/EventVisitorsList", { state: { EventId: dr.EventId } }) }}
                        disabled={!permission.ManageVisitor}
                    />
                </span>
            ),
        },
    ];

    const filterComponent = (
        <>
            <Stack component="container">
                <div className="primary-bg-color p-1 px-4">
                    <Stack direction="row" className='row'>
                        <div className="col-10">
                            <Stack alignItems="center" justifyContent="center">
                                <div elevation={0} className="text-white">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <DateRange
                                            ButtonColor={"white"}
                                            type="day"
                                            setDateRange={({ StartDate, EndDate }) =>
                                                setCondition((dt) => ({ ...dt, StartDate, EndDate }))
                                            }
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                            <Stack alignItems="end" justifyContent="end">
                                <Box sx={{ display: { md: "flex" } }}>
                                {(permission?.ManageAdd) && (
                                    <Button
                                        color="primary"
                                        id={`Add_${AddButtonText}`}
                                        onClick={() => fnEdit(0)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="fa fa-plus-circle me-2"></i>{" "}
                                            <div className="d-none d-md-block">
                                                {`Add ${AddButtonText}`}
                                            </div>
                                        </div>
                                    </Button>
                                )}
                                </Box>
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </Stack>
        </>
    );

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <div></div>
                {/* {filterComponent}  @shubham95shrivas please review this */}
                <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                <TableComponent
                    columns={columns}
                    data={records}
                    bData={bData}
                    MasterPageName={MasterPageName}
                    
                    AddButtonText={AddButtonText}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.ManageEventheaderValueNoResults}
                            subHeaderValue={StandardConst.ManageEventSubHeaderRole}
                            imageUrl={StandardConst.imageNoRecordsFound}
                            actionButton={
                                <>
                                    {permission.ManageAdd && (
                                        <Button id="NoRecordFoundAddEvent" variant="outline-primary" onClick={() => fnEdit(0)}>
                                            Add {AddButtonText}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    }
                    filterData={[
                        {
                            FieldName: "FromDate",
                            DefaultDate : DateTime.local().startOf('month'),
                            FilterType: "DATE",
                            FilterPlaceholder: "Enter From Date",
                          },
                          {
                            FieldName: "ToDate",
                            DefaultDate: DateTime.local().endOf('month'),
                            FilterType: "DATE",
                            FilterPlaceholder: "Enter To Date",
                          },

                    ]}
                    onAddEvent={() => fnEdit(0)}
                    IsAddButtonVisible={permission?.ManageAdd}
                    isSearchRequired={true}
                    allowSerialNo={true}
                    callBackEvent={fetchManageEvents}
                />
                <CreateEvent
                    callBackEvent={async () => await fetchManageEvents()}
                    ref={addEditModalRef}
                >
                </CreateEvent>

                <ModalManageVisitorType
                    callBackEvent={async () => await fetchManageEvents()}
                    ref={refModalManageVisitorType}
                >
                </ModalManageVisitorType>
                <GenerateQR ref={GenerateQRRef} />
            </Container>
        </>
    )
}


const GenerateQR = forwardRef(({ }, ref) => {
    const [show, setShow] = useState(false);
    const [qrData, setQrData] = useState(false);
    const [eventLogo, setEventLogo] = useState(null);

    const handleClose = () => setShow(false);
    useImperativeHandle(ref, () => ({
        openModal: async (QrData, EventLogo) => {
            setEventLogo(EventLogo);
            setQrData(QrData);
            setShow(true);
        },
    }));

    return (
        <Modal
            size="xs"
            centered
            show={show}
            onHide={handleClose}
            className="container-fluid"
        >
            <Modal.Header closeButton>
                <Modal.Title>QR Code for Events</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 m-0">
                <Alert severity="success">
                    Download and paste this QR Code at the entrace of your event. Visitor
                    can scan this QR Code and to self check in
                </Alert>
                <div className="p-3 m-0">
                    <QRCodeGeneratorDownloadComponent data={qrData} showWodLogo={true} showLogo={true} EventLogo={eventLogo} Text="Powered by wiseofficeday.com"/>
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default ManageEvents