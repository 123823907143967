import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import * as yup from "yup";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime, Info } from "luxon";
import _, { map, sortBy } from "underscore";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import { Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";

import { StandardConst } from "../../Services/StandardConst";
import { roundOff } from "../../Services/UtilityService";
import { format } from "date-fns";

const AttendanceReport = () => {
  PageInfo({ pageTitle: "Attendance Report" });

  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
  });
  const [data, setData] = useState(null);
  const [fromdate, setFromdate] = useState(null);
  const [todate, setTodate] = useState(null);
  const [persons, setPersons] = useState([]);
  const renderAfterCalled = useRef(false);
  const dispatch = useDispatch();

  const fetchPersonList = async () => {
    var opt = {
      endPoint: `Visitor/fetchAllEmployees`,
      dispatch,
    };

    let res = await WebService(opt)
      .then((r) => sortBy(r, "FullName"))
      .then((r) =>
        map(r, (m) => ({
          EmployeeId: m.EmployeeId,
          heading: m.FullName, 
          description: m.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`,
          text: m.FullName,
          value: m.EmployeeId
        }))
      );
      const manageSearchAllUsers = ActionPermission("Attendance Report - Search All Users");
    if (!manageSearchAllUsers) {
      const listOfUser = childUser.concat([loggedUser]);
      res = res.filter(
        (f) => listOfUser.filter((u) => u === f.value).length > 0
      );
    }
    setPersons([{ EmployeeId: 0, heading: "All" }].concat(res));
  };
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageSearchAllUsers: ActionPermission("Attendance Report - Search All Users"),
    });
    fetchPersonList();
  }, [permissionList]);

  const onSubmit = async (rec) => {
    console.log(rec)
    rec.ToDate = DateTime.fromJSDate(rec.ToDate).toSQLDate();
    rec.FromDate = DateTime.fromJSDate(rec.FromDate).toSQLDate();
    setFilter(rec);
    rec.EmployeeId = rec.EmployeeId?.length > 0 ? rec.EmployeeId : [persons[0]]
    rec.EmployeeId[0].EmployeeId ??= 0;
    var condition = `(CheckInDate between '${DateTime.fromSQL(rec.FromDate)
      .setZone("utc")
      .toSQL().substring(0,23)}' and '${DateTime.fromSQL(rec.ToDate).plus({day:1}).minus({millisecond:1})
      .setZone("utc")
      .toSQL().substring(0,23)}') and EmployeeId in (${
      rec.EmployeeId[0].EmployeeId == 0
        ? persons
            .filter((f) => f.EmployeeId != 0)
            .reduce((m, v) => `${m},${v.EmployeeId}`, "")
            .substring(1)
        : rec.EmployeeId[0].EmployeeId
    }) `;
    const res = await WebService({
      endPoint: `Attendance/Report?where=${condition}`,
      dispatch,
    }).then((r) =>
      map(r, (m) => {
        m.TotalHours=0;
        if(m.CheckInTime !== null && m.CheckOutTime !== null){
          const checkInDate = format(new Date(m.CheckInDate), "yyyy-MM-dd");
          const checkOutDate = format(new Date(m.CheckOutDate), "yyyy-MM-dd");
          const checkInDateTime = new Date(`${checkInDate} ${m.CheckInTime}`);
          const checkOutDateTime = new Date(`${checkOutDate} ${m.CheckOutTime}`);
          const diffInMilliseconds = checkOutDateTime - checkInDateTime;
          const diffInHour = diffInMilliseconds / (1000 * 60 * 60);

          // m.TotalHours=DateTime.fromISO(`${m.CheckOutTime}Z`).diff(DateTime.fromISO(`${m.CheckInTime}Z`),'hours').toObject().hours;
          m.TotalHours=roundOff(diffInHour,2);
        }
        // if (m.CheckInTime !== null)
        //   m.CheckInTime = DateTime.fromISO(`${m.CheckInTime}Z`)
        //     .setZone("local")
        //     .toFormat("tt");
        // if (m.CheckOutTime !== null)
        //   m.CheckOutTime = DateTime.fromISO(`${m.CheckOutTime}Z`)
        //     .setZone("local")
        //     .toFormat("tt");
        return m;
      })
    );
    setData(res);
  };
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      FromDate: yup
        .date()
        .label("From Date")
        .typeError(requiredMessage)
        .required()
        .when([], (_, passSchema) => {
          return todate ?? "" != ""
            ? passSchema.max(DateTime.fromSQL(todate).toJSDate())
            : passSchema;
        }),

      ToDate: yup
        .date()
        .label("To Date")
        .typeError(requiredMessage)
        .required()
        .when([], (_, passSchema) => {
          return fromdate ?? "" != ""
            ? passSchema.min(DateTime.fromSQL(fromdate).toJSDate())
            : passSchema;
        }),
    })
    .required();
  const filterChange = () => {
    setData(null);
  };
  const TotalHourToHourMinute=(totalHour=null)=>{
    totalHour??=0;
    if(totalHour===0) return "-";
    var hour=parseInt(totalHour);
    var minute=parseInt((totalHour-hour)*60).toString();
    return `${hour}:${"00".substring(0,2-minute.length)}${minute}`;
  }
  const columns = [
    {
      Text: "Person",
      Value: "FullName",
      IsGroupByFeature: true,
    },
    {
      Text: "Check In Date (Latitude, Longitude)",
      Value: "CheckInDate",
      // DateFormat: "yyyy-MM-dd",
      render: (dr) => <span style={dr.CheckInLatitude !== null && dr.CheckInLongitude !== null ? {cursor : "pointer"} : {cursor : ""}} onClick={() => openMap(dr.CheckInLatitude, dr.CheckInLongitude)}>{`${dr.CheckInDate !== null ? format(new Date(dr.CheckInDate), "yyyy-MM-dd") : ""} ${dr.CheckInTime !== null ? format(new Date(`1970-01-01 ${dr.CheckInTime}`), "hh:mm a"): ""} ${(dr.CheckInLatitude !== null || dr.CheckInLongitude !== null) ? `(${dr.CheckInLatitude}, ${dr.CheckInLongitude})` : ``}`}</span>,
      IsGroupByFeature: true,
    },
    {
      Text: "Check Out Date (Latitude, Longitude)",
      Value: "CheckOutDate",
      // DateFormat: "yyyy-MM-dd",
      render: (dr) => <span style={{cursor : "pointer"}} onClick={() => openMap(dr.CheckOutLatitude, dr.CheckOutLongitude)}>{`${dr.CheckOutDate !== null ? format(new Date(dr.CheckOutDate), "yyyy-MM-dd") : ""} ${dr.CheckOutTime !== null ? format(new Date(`1970-01-01 ${dr.CheckOutTime}`), "hh:mm a"): ""} ${(dr.CheckOutLatitude !== null || dr.CheckOutLongitude !== null) ? `(${dr.CheckOutLatitude}, ${dr.CheckOutLongitude})` : ``}`}</span>,
      IsGroupByFeature: true,
    },
    {
      Text: "Total Hours",
      Value:"TotalHours",
      render: (dr)=>(<span>{TotalHourToHourMinute(dr.TotalHours)}</span>),
      // GroupByResult: "Summation",
      style: { width: "120px", "text-align": "right" },
    }
  ];
  useEffect(() => {
    setFromdate(filter.FromDate);
  }, [filter.FromDate]);
  useEffect(() => {
    setTodate(filter.ToDate);
  }, [filter.ToDate]);
  const filterComponent = (
    <>
      {data === null && (
        <div>
          <div className="primary-bg-color pt-2 pb-2 px-4">
            <Form
              defaultValues={filter}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              <Row className="d-flex">
                <Col className="col-md-3 col-12 d-flex align-item-center">
                  <FormInputText
                    label="From Date"
                    name="FromDate"
                    type="date"
                    labelCss="text-light"
                    setValue={setFromdate}
                    max={(todate ?? "") == "" ? undefined : todate}
                  />
                </Col>
                <Col className="col-md-3 col-12 d-flex align-item-center">
                  <FormInputText
                    label="To Date"
                    name="ToDate"
                    labelCss="text-light"
                    type="date"
                    setValue={setTodate}
                    min={(fromdate ?? "") == "" ? undefined : fromdate}
                  />
                </Col>
                <Col className="col-md-3 col-12 white-autocomplete" style={{marginTop : "-4px"}}>
                 { persons.length>0 &&  <FormAutoCompleteDropdownCard
                    name="EmployeeId"
                    data={persons}
                    label="Person"
                    optionText="heading"
                    labelCss="text-light"
                    value={
                      persons?.length > 0 
                        ? persons[0]
                        : null
                    }
                  />}
                </Col>
                <Col className="col-md-3 col-12 d-flex align-item-center justify-content-end">
                  <Button
                    id="btnRunReport"
                    variant="outline-primary"
                    type="submit"
                    className="text-light css-1y942vo-MuiButtonBase-root-MuiButton-root"
                  >
                    Run Report
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </>
  );
  const MasterPageName = "Attendance Report";

  const openMap = (latitude, longitude) => {
    if(latitude !== null && longitude !== null){
      const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  
      // Set the width, height, and position of the new window
      const width = (window.screen.width * 80) / 100;
      const height = (window.screen.height * 80) / 100;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
    
      // Open a new window with controlled features
      window.open(
        mapUrl,
        "_blank",
        `width=${width},height=${height},top=${top},left=${left},resizable=0,scrollbars=0,toolbar=0,location=0,directories=0,status=0,address=0`
      );
    }
  };
  
  const reportResultComponent = (
    <>
      {data !== null && (
        <div>
          <div style={{ backgroundColor: "#1976D2" }} className="p-1">
            <Row>
              <Col>
                <div className="float-start p-1">
                  <span className="h5 me-2 text-light">
                    Detailed Attendance Report: {filter.FromDate}
                    {" — "}
                    {filter.ToDate}
                  </span>
                </div>

                <div className="float-end">
                  <Button
                    id="btnAttendanceFilter"
                    className="text-light"
                    variant="outline-primary"
                    onClick={filterChange}
                  >
                    Change Filters
                  </Button>
                </div>
              </Col>  
            </Row>
          </div>
          <div>
            <Row>
              <Col>
                <Row className="ms-2">
                  <Col className="col-md-1 font-bold">Team:</Col>
                  <Col className="col-md-11 font-bold">
                    {filter.EmployeeId[0].EmployeeId == 0
                      ? "Everyone"
                      : persons.filter((f) => f.EmployeeId == filter.EmployeeId[0].EmployeeId)[0]
                          .heading}
                  </Col>
                </Row>
              </Col>
            </Row>
            <TableComponent
              columns={columns}
              data={data}
              IsAddButtonVisible={false}
              excelExportFileName={"Attendance"}
              noRecordCss="p-0"
              noRecordFound={
                <NoRecordTemplate
                  headerValue={StandardConst.AttendanceReportheaderValueNoResults}
                  subHeaderValue={StandardConst.AttendanceReportSubHeaderRole}
                  imageUrl={StandardConst.imageNoRecordsFound}
                  actionButton={
                    <>
                      <Button variant="outline-primary" onClick={filterChange}>
                        Change Filters
                      </Button>
                    </>
                  }
                />
              }
              allowSerialNo={true}
            />
            <div className="px-3">
              <span className="float-end"><b>Total Hours : {data !== null && data.length > 0 ? TotalHourToHourMinute(roundOff(data.reduce((sum, item) => sum + item.TotalHours, 0),2)) : 0}</b></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
  const [bData, setBData] = React.useState([
    {
      title: "Report",
      hrefLink: "#",
    },
    {
      title: "Attendance Report",
      hrefLink: "#",
    },
  ]);
  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <h3 className="ms-4 mt-2">{MasterPageName}</h3>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        {filterComponent}
        {reportResultComponent}
      </Container>
    </>
  );
};

export default AttendanceReport;
