import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent'
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { WebService } from '../../Services/WebService';
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import Button from "react-bootstrap/esm/Button";
import AddEditAccessGroup from './AddEditAccessGroup';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { useNavigate } from "react-router-dom";
import { PageInfo } from '../PageInfo';
import { Form, FormCheckRadioInput } from '../Form';
import { Box } from "@mui/material"; 
const AccessGroup = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    PageInfo({ pageTitle: "Access Group" });
    useEffect(() => {
        SetPermission({
            ManageAdd: true, // ActionPermission("Visitor Type - Add"),
            ManageEdit: true, // ActionPermission("Visitor Type - Edit"),
            ManageDelete: true, // ActionPermission("Visitor Type - Delete"),
        });
    }, [permissionList]);


    const fetchAccessGroups = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accessgroup?CompanyId=${CompanyInfo.CompanyId ?? 0}`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAccessGroups();
    }, []);


    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Access Group";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Access Group";
    const dispatch = useDispatch();
    const onDelete = async (AccessGroupId) => {
        await WebService({
            endPoint: `CommonUtility/accessgroup?AccessGroupId=${AccessGroupId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAccessGroups();
    };

    const [bData,] = React.useState([
        {
            title: "Access Management",
            hrefLink: "#",
        },
        {
            title: "Access Group",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Access Group Name",
            Value: "AccessGroupName"
        },
        {
            Text: "Parent Name",
            Value: "ParentGroupId",
            render: (dr) => (
                <>
                    {dr.ParentGroupId !== null ? records?.find((item) => item.AccessGroupId === dr.ParentGroupId)?.AccessGroupName : "-"}
                </>
            )
        },
        {
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    {dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <div className='d-flex justify-content-center'>
                    <ActionButton
                        onClick={() => fnEdit(dr.AccessGroupId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Access Group"}
                        id={`btnAccessGroupEdit_${dr.AccessGroupId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.AccessGroupName, // Message of Alert
                                dr.AccessGroupId  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Access Group"}
                        id={`btnAccessGroupDelete_${dr.AccessGroupId}`}
                    />
                    <ActionButton
                        onClick={() => { 
                            navigate("/AssignAccessLevels", { state: { AccessGroupId: dr.AccessGroupId } }) }
                        }
                        disabled={!permission.ManageEdit}
                        IconName="Permission"
                        IconTooltip={"Assign Access Levels"}
                        id={`btnAccessGroupSomething_${dr.AccessGroupId}`}
                    />
                </div>
            ),
        },
    ];

    // if (Roles[0] === StandardConst.SystemRole.SuperAdmin) {
    //     columns.splice(1, 0, {
    //         Text: "Access",
    //         Value: "CompanyName"
    //     });
    // }

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.AccessGroupheaderValueNoResults}
                        subHeaderValue={StandardConst.AccessGroupSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button id="NoRecordFoundAddAccessGroup" variant="outline-primary" onClick={() => fnEdit(0)}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAccessGroup
                callBackEvent={async () => await fetchAccessGroups()}
                ref={addEditModalRef}
            ></AddEditAccessGroup>
        </>
    )
}

export default AccessGroup;