import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, {  } from "underscore";
import { ActionPermission, PageInfo } from "../PageInfo";
import { Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";

import { StandardConst } from "../../Services/StandardConst";
import { DateRange } from "../Dashboard/Company/CompanyDashboard";
import ActionButton from "../../Services/ActionButton";
import BootstrapModal from "react-bootstrap/Modal";


const AttendanceSummaryReport = () => {
  const dispatch = useDispatch();

  PageInfo({ pageTitle: "Attendance Summary Report" });
  const MasterPageName = "Attendance Summary Report";
  const refModalInformation = useRef();
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);

  const [permission, SetPermission] = useState({});
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
  });
  
  const [attendanceData, setAttendanceData] = useState([]);
  const [daysArray,  setDaysArray] = useState(new Array(31).fill(null));

  useEffect(() => {
    SetPermission({
      ManageSearchAllUsers: ActionPermission("Attendance Report - Search All Users"),
    });
  }, [permissionList]);

  const [bData, setBData] = React.useState([
    {
      title: "Report",
      hrefLink: "#",
    },
    {
      title: "Attendance Summary Report",
      hrefLink: "#",
    },
  ]);

  const fetchAttendanceList = async (SelectedYear, SelectedMonth) => {
    const NumberOfDasInMonth = new Date(SelectedYear, SelectedMonth, 0);
    // it is blank array for days
    const daysArray = new Array(NumberOfDasInMonth.getDate()).fill(null);
    var daysObject = daysArray.reduce((acc, _, index) => {
        const day = index + 1;
        acc[day] = null; 
        return acc;
    }, {});
    setDaysArray(new Array(NumberOfDasInMonth.getDate()).fill(null));

    let attendanceData = [];

    const EmployeeList = await WebService({
        endPoint: `CommonUtility/Edit/employees?CompanyId=${StandardConst.YesNo.Yes}&StatusId=${StandardConst.Status.Approved}`,
        dispatch,
    })

    const EmployeeCheckInList = await WebService({
        endPoint: `Attendance/getEmployeeCheckInList/${SelectedYear}/${SelectedMonth}`,
        dispatch,
    });

    const HolidayList = await WebService({
        endPoint: `CommonUtility/Edit/holidaymaster?CompanyId=${StandardConst.YesNo.Yes}`,
        dispatch,
    });
    const filteredHolidays = HolidayList.filter(holiday => {
        const holidayDate = new Date(holiday.HolidayDate);
        return holidayDate.getFullYear() === 2024;
    }).map(holidayData => {
      return {
        HolidayDate: holidayData.HolidayDate,
        HolidayDay: new Date(holidayData.HolidayDate).getDate(),
        HolidayName: holidayData.HolidayName,
      }
    });


    let EmployeeLeaveList = await WebService({
        endPoint: `Attendance/getEmployeeLeaveList/${SelectedYear}/${SelectedMonth}`,
        dispatch,
      });

      EmployeeList.map((employee, i) => {
        const obj = {
          SN : parseInt(i+1),
          EmployeeName : employee.FullName,
          Attendance : ""
        };

        
        Object.keys(daysObject).map((key) => {
          filteredHolidays.map((holiday) => {
            const holidayDate = new Date(holiday.HolidayDate);
            const holidayMonth = holidayDate.getMonth() + 1;
            if (parseInt(holiday.HolidayDay) === parseInt(key) && parseInt(SelectedMonth) === parseInt(holidayMonth)) {
              const oj = {Holiday : 1}
              daysObject[key] = {...daysObject[key], ...oj};
            }
          })

          EmployeeCheckInList.filter(item => item.EmployeeId === employee.EmployeeId).map((employeeCheckIn) => {
            if (parseInt(employeeCheckIn.CheckInDay) === parseInt(key)) {
              const oj = {CheckIn : 1}
              daysObject[key] = {...daysObject[key], ...oj};
            }
          })

          EmployeeLeaveList.filter(item => item.EmployeeId === employee.EmployeeId).map((EmployeeLeave) => {
            if (parseInt(EmployeeLeave.LeaveDay) === parseInt(key)) {
              const oj = {Leave : 1}
              daysObject[key] = {...daysObject[key], ...oj};
            }
          })


        });


        const concatenatedObject = {
          ...obj,
          ...daysObject 
        };
        attendanceData.push(concatenatedObject);

        daysObject = daysArray.reduce((acc, _, index) => {
          const day = index + 1;
          acc[day] = null;
          return acc;
        }, {});
        
      });

    setAttendanceData(attendanceData);
  };

  useEffect(() => {
    const date = new Date();
    const selectedYear = date.getFullYear();
    let selectedMonth = (date.getMonth() + 1).toString();
    fetchAttendanceList(selectedYear, selectedMonth);
  }, []);

  const stylecss = {textAlign: 'left !important'};

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        <div className="row primary-bg-color text-white p-0 m-0" style={{height : "40px"}}>
            <div className="col-11 d-flex justify-content-center align-items-center">
              <DateRange
                  ButtonColor={"white"}
                  setDateRange={({ StartDate, EndDate }) => {
                          const date = new Date(StartDate);
                          const selectedYear = date.getFullYear();
                          let selectedMonth = (date.getMonth() + 1).toString(); 
                          fetchAttendanceList(selectedYear, selectedMonth);
                  }}
              />
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center">
              <OpenInfoModal ref={refModalInformation} />
              <ActionButton
                  className="text-align-end text-white"
                  onClick={async () => await refModalInformation.current.openModal()}
                  IconName="Information"
                  IconTooltip="Information"
                  id="info"
              />
            </div>
        </div>
        
        <div className="mt-2">
            {(attendanceData.length > 0) ? (
              <div className="table-responsive p-0" style={{ overflow: "none" }}>
                <table>
                    <thead>
                        <tr className="bg-info">
                            <th >No.</th>
                            <th >Name</th>
                            {daysArray.map((_, index) => (
                                <th key={index} >{parseInt(index+1) < 10 ? '0' + parseInt(index+1) : parseInt(index+1)}</th>
                            ))}
                            {/* <th >Attendance</th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {attendanceData.map((employeeRow, index) => {
                        
                            return (
                                <tr key={index}>
                                    <td>{employeeRow.SN}</td>
                                    <td style={stylecss}>{employeeRow.EmployeeName}</td>
                                    {daysArray.map((_, j) => {
                                      const dayValues = employeeRow[j + 1];
                                      if(dayValues !== null){
                                        var colorDot = 'blue';

                                        if((dayValues?.Leave === 1) && (dayValues?.CheckIn === undefined) && (dayValues?.Holiday === undefined)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.Leave;
                                        }else if((dayValues?.Leave === undefined) && (dayValues?.CheckIn === 1) && (dayValues?.Holiday === undefined)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.CheckIn;
                                        }else if((dayValues?.Leave === 1) && (dayValues?.CheckIn === 1) && (dayValues?.Holiday === undefined)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.LeaveAndCheckIn;
                                        }else if((dayValues?.Leave === undefined) && (dayValues?.CheckIn === undefined) && (dayValues?.Holiday === undefined)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.AllNotFound;
                                        }else if((dayValues?.Leave === undefined) && (dayValues?.CheckIn === undefined) && (dayValues?.Holiday === 1)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.Holiday;
                                        }else if((dayValues?.Leave === undefined) && (dayValues?.CheckIn === 1) && (dayValues?.Holiday === 1)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.CheckInAndHoliday;
                                        }else if((dayValues?.Leave === 1) && (dayValues?.CheckIn === undefined) && (dayValues?.Holiday === 1)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.LeaveAndHoliday;
                                        }else if((dayValues?.Leave === 1) && (dayValues?.CheckIn === 1) && (dayValues?.Holiday === 1)){
                                          colorDot = StandardConst.AttendanceSummaryReportColors.LeaveAndCheckInAndHoliday;
                                        }

                                        return <td key={j+1}><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: colorDot }}></span></td>
                                      }else{
                                        return <td></td>
                                      }
                                    })}
                                    {/* <td></td> */}
                                </tr>
                            );
                        return null;
                    })}
                    </tbody>
                </table>
              </div>
            ) : (
                <NoRecordTemplate
                    headerValue="No employees found"
                    subHeaderValue="Please add some employees"
                    imageUrl={StandardConst.imageNoRecordsFound}
                />
            )}
        </div>
      </Container>
    </>
  );
};

const OpenInfoModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async () => {
      setShow(true);
    },
  }));

  return (
    <BootstrapModal show={show} onHide={handleClose}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Associated colors for Attendance Summary</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <table style={{width: "100%"}}>
          <tr>
            <th>Leave</th>
            <th>CheckIn</th>
            <th>Holiday</th>
            <th>Color</th>
          </tr>
          <tr>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.Leave }}></span></td>
          </tr>
          <tr>
            <td>0</td>
            <td>1</td>
            <td>0</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.CheckIn }}></span></td>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>1</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.Holiday }}></span></td>
          </tr>
          <tr>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.LeaveAndCheckIn }}></span></td>
          </tr>
          <tr>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.CheckInAndHoliday }}></span></td>
          </tr>
          <tr>
            <td>1</td>
            <td>0</td>
            <td>1</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.LeaveAndHoliday }}></span></td>
          </tr>
          <tr>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.LeaveAndCheckInAndHoliday }}></span></td>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: StandardConst.AttendanceSummaryReportColors.AllNotFound }}></span></td>
          </tr>
          
          
        </table>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
});

export default AttendanceSummaryReport;
