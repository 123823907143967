import React, { useState, useEffect, useRef, memo } from "react";
import { WebService } from "../../Services/WebService";
import AddEditCustomer from "./AddEditCustomer";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
const apiUrl = "Customer";
const Customer = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Customer" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);


  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Customer - Add"),
      ManageEdit: ActionPermission("Customer - Edit"),
      ManageDelete: ActionPermission("Customer - Delete"),
    });
  }, [permissionList]);

  const fetchCustomer = async () => {
    await WebService({
      endPoint: `CommonUtility/fetchAll/customerdetails?CompanyId=${StandardConst.YesNo.Yes}`,
      dispatch,
    }).then(setRecords);
    dispatch(ConfigurationflowStatusUpdate());
  };

  const getUserOfCustomer = async(CustomerId) => {
    const usersListOfCustomer = await WebService({
      endPoint: `${apiUrl}/FetchUserOfCustomer/${CustomerId}`,
      dispatch,
    });

    var userList = usersListOfCustomer.slice(0, 5).map(item => item.FullName.charAt(0).toUpperCase() + item.FullName.slice(1)).join(', ');
    ref.current.confirmAlert(
      "Delete", //Confirm button text
      "Are You Sure", // Text if Alert
      "Do you want to delete customer along with users " + 
      (usersListOfCustomer.length <=5 ?(userList): `${userList} ...`)
      ,CustomerId
    )
  };

  const onDelete = async (CustomerId) => {
    await WebService({
      endPoint: `${apiUrl}/${CustomerId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchCustomer();
  };
  const addEditModalRef = useRef();
  useEffect(() => {
    fetchCustomer();
  }, []);
  const columns = [
    {
      Text: "Company Name",
      Value: "Name",
    },
    {
      Text: "Primary Contact Person ",
      Value: "PersonName",
    },

    {
      Text: "Primary Contact Phone",
      Value: "Mobile",
    },
    {
      Text: "Address",
      Value: "Address",
    },
    {
      Text: "Action",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr)=>(
        <span className="d-flex">
          <ActionButton
            onClick={() =>
              fnEdit(dr.CustomerId)
            }
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id={`btnCustomerEdit_${dr.CustomerId}`}
          />

          <ActionButton
            onClick={() =>
              getUserOfCustomer(dr.CustomerId)
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id={`btnCustomerDelete_${dr.CustomerId}`}
          />
        </span>
      ),
    },
  ];
  const [bData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Customer",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Customer";
  const confirmMessage = MasterPageName + " Deleted successfully";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.CustomerheaderValueNoResults}
            subHeaderValue={StandardConst.CustomerSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${MasterPageName}`}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditCustomer
        callBackEvent={() => fetchCustomer()}
        ref={addEditModalRef}
      ></AddEditCustomer>
    </>
  );
};
export default memo(Customer);
