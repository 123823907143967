import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { StandardConst } from "./StandardConst";
import Cookies from "universal-cookie";
import { Avatar } from "@mui/material";
const CompanyInfoWide = ({
  isMobileVisible,
  section1,
  section2,
  section3,
  ...rest
}) => {
  isMobileVisible ??= false;
  section1 ??= 2;
  section2 ??= 6;
  section3 ??= 4;
  const cookies = new Cookies();
  const [companyInfo, setCompanyInfo] = useState();
  useEffect(() => {
    setCompanyInfo(cookies.get("company") ?? {});
  }, []);
  
  useEffect(() => {
    getCompanyLogo();
  }, [companyInfo?.Logo]);
  const [logosrc, setLogoSrc] = useState("");
  const getCompanyLogo = async () => {
    const companyLogoName = companyInfo?.Logo;
        const logoData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getLogo/${companyLogoName}`);
        const logoText = await logoData.text();
        setLogoSrc(logoText);
  }
  
  return (
    <>
      <Row
        // sx={{
        //   alignItems: "center",
        // }}
      >
        <Col xs={4} md={section1} className="col-12">
        <div
  style={{
    paddingTop: '1px',
    width: '100%', // Use percentage for width to allow flexibility
    maxWidth: '150px', // Set a maximum width to prevent it from exceeding 95px
    height: '75px', // Fixed height for the container
    overflow: 'hidden', // Hide overflow if the image is too tall
    borderRadius: '8px', // Rounded corners
    display: 'flex', // Center content horizontally and vertically
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <img
    src={logosrc}
    alt={companyInfo?.CompanyName || 'Company Logo'}
    style={{
      width: '100%', // Fill the container width
      height: '100%', // Fill the container height
      objectFit: 'contain', // Ensure the image fits within the container without cropping
      maxHeight: '100%', // Prevent image from exceeding container height
      maxWidth: '100%', // Prevent image from exceeding container width
      borderRadius: '8px'
    }}
    onError={(e) => {
      e.target.src = `https://via.placeholder.com/95x50?text=${companyInfo?.CompanyName?.charAt(0).toUpperCase() }`; // Fallback for broken images
    }}
  />
</div>
        </Col>
        <Col xs={4} md={section2} className="col-12">
          <h4 className="text-center">{companyInfo?.CompanyName ?? ""}</h4>
          <h6 className="text-center">
            {`${companyInfo?.Address1 ?? ""}, ${companyInfo?.Address2 ?? ""}, ${
              companyInfo?.Address3 ?? ""
            }`
              .replaceAll(", ,", "")
              .trim()}
          </h6>
          <h6 className="text-center">
            {`${companyInfo?.city ?? " "}, ${companyInfo?.State ?? ""} - ${
              companyInfo?.pincode ?? ""
            }`
              .replaceAll(",  - ", ", ")
              .replaceAll(" , ", "")}
          </h6>
        </Col>
        {isMobileVisible === false && (
          <Col xs={4} md={section3} className="col-12">
            <h6></h6>
            <h6 className="text-center">
              {" "}
              <i className="fa fa-phone mx-1 "></i>
              {companyInfo?.Phone}
            </h6>
            <h6 className="text-center">
              {" "}
              <i className="fa fa-envelope mx-1 "></i>
              {companyInfo?.Email}
            </h6>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CompanyInfoWide;
