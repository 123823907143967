import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { PureComponent, useEffect, useState, useRef } from "react";
import "./CompanyDashboard.css";
import Button from "react-bootstrap/esm/Button";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts";
import { StandardConst } from "../../../Services/StandardConst";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MuiButton from "@mui/material/Button";
import ReactTimeAgo from "react-time-ago";
import wsTheme from "../../../Services/WsMuiTheme";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material";
import { extend, findWhere, map, sortBy, where } from "underscore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { generateQueryStringByObject } from "../../../Services/UtilityService";
import { memo } from "react";
import { Notification } from "../DashboardCommonComponent";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ActionPermission, PageInfo } from "../../PageInfo";
import {
  CheckInCheckOutButton,
  EmployeeAnniversaries,
  EmployeeAttendanceDayWise,
  EmployeeBirthdayCarousel,
} from "../Employee/EmployeeDashboard";
import FlowDashboardButton from "../../ConfigurationFlow/FlowComponent/FlowDashboardButton";
import Row from "react-bootstrap/esm/Row";
import AddEditRoles from "../../Static/AddEditRoles";
import InviteUser from "../../ConfigurationFlow/FlowComponent/InviteUser";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import { ConfigurationActions } from "../DashboardComponent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Form, FormInputText } from "../../Form";
import Carousel from "react-bootstrap/Carousel";
import CloseIcon from '@mui/icons-material/Close';
import { MyTeam } from "../../../Services/MyTeam";
import { logout } from "../../../Services/authSlice";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const CompanyDashboard = () => {
  PageInfo({ pageTitle: "Dashboard" });
  return (
    <>
      <div className="container">

        <ConfigurationShowYellowBar />
        <SystemMaintenanceShowYellowBar/>

        {/* <WiseOfficeDayHeader /> */}
        {/* <div className="card card-cd mb-3 mt-3">
          <div className="card-body p-0 p-2">
            <div className="d-flex flex-column flex-lg-row flex-sm-row">
              <ProfileSummary className="col-md-5 col-sm-9" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <AttendanceSummary className="col-md-4 col-sm-3 d-flex justify-content-center align-items-center text-center" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <NotificationIcon className="col-md-3 col-sm-3 d-flex justify-content-end pe-3" />
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <EmployeeLeaveChart />
              </div>
              <div className="col-md-8 col-sm-12 col-xs-12">
                <EmployeeSalaryChart />
              </div>
            </div>
          </div>
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <CompanyProjects />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <MyTeam />
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <ConfigurationManager />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <ConfigurationActions />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeAttendanceDayWise />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeBirthdayCarousel />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeAnniversaries />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeOnLeave />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ContactUs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WiseOfficeDayHeader = () => {
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    useEffect(() => {
      SetPermission({
          SeeAllEmployeeAttendance : ActionPermission("Employee - See all Attendance Summary")
      });
  }, [permissionList]);

    const dispatch = useDispatch();
    const fnLogout = async () => {
      dispatch(logout());
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
      "& .MuiBadge-badge": {
        right: -3,
        top: 8,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
      },
    }));

    return (
      <div className="card card-cd mb-3 mt-3">
        <div className="card-body p-0 p-2">
          <div className="d-flex flex-column flex-lg-row flex-sm-row">
            <ProfileSummary className="col-md-5 col-sm-9" />
            <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
            {permission.SeeAllEmployeeAttendance ? (
              <>
                <AttendanceSummary className="col-md-4 col-sm-3 d-flex justify-content-center align-items-center text-center" />
                <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              </>
            ) : (
              <div className="col-md-4 col-sm-3 d-flex justify-content-center align-items-center text-center"></div>
            )}
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="row">
                <CheckInCheckOutButton className="col-md-7 col-5 d-flex justify-content-center align-items-center text-center p-0" />
                <NotificationIcon className="col-md-2 col-4 d-flex justify-content-center pe-3" />
                <div className="col-md-3 col-3 d-flex justify-content-center pe-3">
                  <div className=" d-flex justify-content-center align-items-center text-center">

                  <IconButton aria-label="cart" onClick={() => fnLogout()}>
                    <StyledBadge
                      color="secondary"
                    >
                      <PowerSettingsNewIcon
                        sx={{ color: StandardConst.Colors.primary, fontSize: 30 }}
                      />
                    </StyledBadge>
                  </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export const DateRange = ({ setDateRange, type, ButtonColor, ResetDateRange, DefaultDate }) => {
  ResetDateRange ??= false;
  setDateRange ??= () => {};
  type ??= "month";
  ButtonColor ??= "primary";
  DefaultDate ??= '';

  const [condition, setCondition] = useState({});

  useEffect(() => {
    if(ResetDateRange){
      if(DefaultDate !== ''){
        setCondition({
          StartDate: DefaultDate.startOf(type),
          EndDate: DefaultDate.endOf(type),
        })
      }else{
        setCondition({
          StartDate: DateTime.now().startOf(type),
          EndDate: DateTime.now().endOf(type),
        })
      }
    }else{
      setCondition({
        StartDate: DateTime.now().startOf(type),
        EndDate: DateTime.now().endOf(type),
      })
    }
  },[ResetDateRange]);

  useEffect(() => {
    setDateRange(condition);
  }, [condition]);

  const calendraRef = useRef(null);
  const handleClickedDateCalendra = () => {
    if(calendraRef.current){
      const InputClicked = calendraRef.current.querySelector('input')
      InputClicked.click();
    }
  }

  const handleCurrentDate = (val) => {
    var normalDate = new Date(val);
    var luxonDate = DateTime.fromJSDate(normalDate);
    setCondition({
      StartDate: luxonDate,
      EndDate: luxonDate,
    });
  };
  return (
    <>
      <div>
        <ThemeProvider theme={wsTheme}>
          <IconButton
            color={ButtonColor}
            onClick={() => {
              var StartDate = condition.StartDate;
              StartDate =
                type === "month"
                  ? StartDate.minus({ month: 1 })
                  : type === "year"
                  ? StartDate.minus({ year: 1 })
                  : StartDate.minus({ day: 1 });
              var EndDate = StartDate.endOf(type);
              setCondition((dt) => ({ ...dt, StartDate, EndDate }));
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <span onClick={() => handleClickedDateCalendra()} style={{cursor: "pointer"}}>
            {type === "month" &&
              (condition?.StartDate ? (condition?.StartDate ?? DateTime.now()).toFormat("MMM yy") : "Filter Date")}
            {type === "day" &&
              (condition?.StartDate ? (condition?.StartDate ?? DateTime.now()).toFormat("dd MMM yy") : "Filter Date")}
            {type === "year" &&
              (condition?.StartDate ? (condition?.StartDate ?? DateTime.now()).toFormat("yyyy") : "Filter Date")}
          </span>
          {/* clicked Component */}
          <span ref={calendraRef} style={{position: 'absolute', left: '-9999px'}}>
            <Form>
            <FormInputText
              name="Calendra"
              type="date"
              max="2999-12-31"
              setValue={(val) => handleCurrentDate(val)}
            />
            </Form>
          </span>
          <IconButton
            color={ButtonColor}
            onClick={() => {
              var StartDate = condition.StartDate;
              StartDate =
                type === "month"
                  ? StartDate.plus({ month: 1 })
                  : type === "year"
                  ? StartDate.plus({ year: 1 })
                  : StartDate.plus({ day: 1 });
              var EndDate = StartDate.endOf(type);
              setCondition((dt) => ({ ...dt, StartDate, EndDate }));
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </ThemeProvider>
      </div>
    </>
  );
};

export const NotificationIcon = ({ ...rest }) => {
  const [state, setState] = useState([]);
  var DateT = "2023-05-06 10:05:06";
  const dispatch = useDispatch();
  const fetchNotReadedNotification = () => {
    WebService({ dispatch, endPoint: `Notification?Skip=0&Take=500` }).then(
      (r) => {
        setState(
          map(r, (m) => {
            if ((m.Route ?? "") !== "") {
              m.Route = `/${m.Route}?search=${window.btoa(m.UniqueRefNo)}`;
            }
            return m;
          })
        );
      }
    );
  };
  useEffect(() => {
    Promise.all([fetchNotReadedNotification()]);
  }, []);
  const navigate = useNavigate();
  const fnRead = async (dr) => {
    const process = [];
    process.push(
      WebService({
        dispatch,
        endPoint: `Notification/MarkRead/${dr.NotificationId}`,
        method: "PUT",
        requiredLoader: false,
      })
    );
    if ((dr.Route ?? "") !== "") {
      process.push(navigate(dr.Route));
    }
    await Promise.all(process);
    await fetchNotReadedNotification();
  };
  //#region Notofication List

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //#endregion

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(4),
      minWidth: 180,
      maxWidth: 650,
      maxHeight: 500,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 15,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const StyledChip = styled(MuiButton)(({ theme }) => ({
    borderRadius: 4,
    fontSize: 11,
    "& .MuiChip-root": {},
  }));
  return (
    <div {...rest}>
      {/* <div>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Dashboard
        </Button>
      </div> */}
      <div className=" d-flex justify-content-center align-items-center text-center">
        <IconButton aria-label="cart" onClick={handleClick}>
          <StyledBadge
            badgeContent={JSON.stringify(
              where(state, { IsReaded: false }).length
            )}
            color="secondary"
          >
            <CircleNotificationsIcon
              sx={{ color: StandardConst.Colors.primary, fontSize: 30 }}
            />
          </StyledBadge>
        </IconButton>

        <StyledMenu
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {map(state, (m, key) => (
              <List
                key={key}
                sx={{
                  width: "100%",
                  maxWidth: 800,
                  className: "d-flex flex-row pb-0 pt-0",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem
                  onClick={handleClose}
                  disableRipple
                  style={{
                    width: "100%",
                    "margin-top": "-19px",
                    "margin-bottom": "-10px",
                  }}
                  className="d-flex flex-row "
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={m?.TriggerName}
                      src={`${StandardConst.uploadImages}${
                        m?.ProfileImage ?? ""
                      }`}
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    onClick={() => fnRead(m)}
                    className="font-size-13 text-wrap  w-100"
                  >
                    {!m.IsReaded && (
                      <strong className="font-size-13 text-wrap">
                        {m.Message}
                      </strong>
                    )}
                    {m.IsReaded && (
                      <>
                        <span className="font-size-13">{m.Message}</span>
                      </>
                    )}
                    <br></br>
                    <ReactTimeAgo
                      date={m.CreatedOn}
                      locale="en-US"
                      className="font-size-13 text-wrap"
                    />
                  </ListItemText>
                  <MuiButton
                    color="primary"
                    size="small"
                    onClick={() => fnRead(m)}
                  >
                    {findWhere(StandardConst.TriggerMaster, {
                      Trigger: m.TriggerId,
                    })?.Action ?? "Read"}
                  </MuiButton>
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
              /* <MenuItem
                onClick={handleClose}
                disableRipple
                style={{ width: "100%" }}
              >
                <div className="row w-100 p-0">
                  <div className="col-md-1  d-flex flex-column justify-content-center">
                    <Avatar
                      className="ps-2"
                      alt={m?.TriggerName}
                      src={`${StandardConst.uploadImages}${
                        m?.ProfileImage ?? ""
                      }`}
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                    />
                  </div>
                  <div className="col-md-11 p-1 ps-4">
                    <span
                      className="font-size-13 text-wrap"
                      onClick={() => fnRead(m)}
                    >
                      {!m.IsReaded && <strong>{m.Message}</strong>}
                      {m.IsReaded && <>{m.Message}</>}
                    </span>
                    <div>
                      <div className="font-size-13 text-muted float-start">
                        <ReactTimeAgo date={m.CreatedOn} locale="en-US" />
                      </div>
                      <div className="font-size-13 float-end">
                        <MuiButton
                          color="primary"
                          size="small"
                          onClick={() => fnRead(m)}
                        >
                          {findWhere(StandardConst.TriggerMaster, {
                            Trigger: m.TriggerId,
                          })?.Action ?? "Read"}
                        </MuiButton>
                      </div>
                    </div>
                  </div>
                </div>
              </MenuItem>

              <Divider sx={{ mx: 2, backgroundColor: "#9a9a9a" }} /> */
          ))}

          {state.length === 0 && (
            <span className="p-2">You don't have any notification</span>
          )}
          {/* <List
            sx={{
              width: "100%",
              maxWidth: 560,
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              {state.length > 0 && (
                <ListItem>
                  <ListItemText>
                    <MuiButton
                      className="font-size-13 text-center"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/Common/Notification`)}
                    >
                      All Notification(s)
                    </MuiButton>
                  </ListItemText>
                </ListItem>
              )}
            </ListItem>
          </List> */}
          {state.length > 0 && (
            <div className="d-flex justify-content-center align-items-center my-1 ">
              <MuiButton
                color="primary"
                size="small"
                onClick={() => navigate(`/Common/Notification`)}
              >
                All Notification(s)
              </MuiButton>
            </div>
          )}
        </StyledMenu>
      </div>
    </div>
  );
};

export const EmployeeLeaveChart = memo(({ Self }) => {
  Self ??= false;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState([
    {
      name: "No Leaves",
      value: 1,
    },
  ]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/LeaveStatus?${generateQueryStringByObject({
        StartDate: condition.StartDate.toSQLDate(),
        EndDate: condition.EndDate.toSQLDate(),
        Self,
      })}`,
    }).then((r) => {
      setData(
        map(r, (m) => {
          m.value = parseFloat(m.value);
          return m;
        })
      );
    });
  };
  const [condition, setCondition] = useState({});
  useEffect(() => {
    if (condition.StartDate !== undefined) Promise.all([fetchData()]);
  }, [condition]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    StandardConst.GraphColors.info,
    StandardConst.GraphColors.red,
    StandardConst.GraphColors.lightGrey,
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const NoDataRrenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return <></>;
  };
  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-6 d-flex justify-content-start">
          <h5 className="card-title">Leave Summary</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <DateRange
            setDateRange={({ StartDate, EndDate }) =>
              setCondition((dt) => ({ ...dt, StartDate, EndDate }))
            }
          />
        </div>
      </div>

      <div className="card-body card-body-cd p-2">
        {data?.length > 0 && (
          <div className="row d-flex flex-row">
            <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center">
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width={300} height={150}>
                  <Pie
                    data={data}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <React.Fragment key={index}>
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                      </React.Fragment>
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
              {data?.map((m, index) => (
                <div className="d-flex flex-row align-items-center font-size-13" key={index}>
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 30,
                        backgroundColor: COLORS[index % COLORS.length],
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-start align-items-start  ms-2">
                    <span>{m.name}</span>
                    <span>
                      <strong> {m.value}</strong>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {data?.length <= 0 && (
          <div className="row d-flex flex-row">
            <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center">
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width={300} height={150}>
                  <Pie
                    data={noData}
                    labelLine={false}
                    label={NoDataRrenderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {noData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[5]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
              {noData?.map((m, index) => (
                <div className="d-flex flex-row align-items-center font-size-13" key={index}>
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 30,
                        backgroundColor: COLORS[5],
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-start align-items-start  ms-2">
                    <span>{m.name}</span>
                    <span>
                      <strong>0</strong>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export const EmployeeSalaryChart = memo(({ Self }) => {
  Self ??= false;
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const fetchData = async (startDate, endDate) => {
    var dt = await WebService({
      dispatch,
      endPoint: `Report/SalaryStatus?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId,
        StartDate: startDate.setZone("utc").toSQL().substring(0, 23),
        EndDate: endDate.setZone("utc").toSQL().substring(0, 23),
        EemployeeId: Self ? loggedUser : 0,
      })}`,
    });
    dt = dt.length > 0 ? dt[0] : {};
    dt.name = startDate.toFormat("MMM");
    dt.Date = startDate;
    setData((d) => {
      if (where(d, { Date: d.Date }).length > 0) return [...d];
      return [...d, dt];
    });
  };

  const [condition, setCondition] = useState({});
  useEffect(() => {
    if (
      (companyInfo.CompanyId ?? 0) !== 0 &&
      loggedUser !== 0 &&
      condition.StartDate !== undefined
    ) {
      setData([]);
      var task = [];
      for (
        var startDate = condition.StartDate;
        startDate < condition.EndDate;
        startDate = startDate.plus({ month: 1 })
      ) {
        task.push(fetchData(startDate, startDate.endOf("month")));
      }
      Promise.all(task);
    }
  }, [companyInfo.CompanyId, loggedUser, condition.StartDate]);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-6 d-flex justify-content-start">
          <h5 className="card-title">Salary Summary</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <DateRange
            setDateRange={({ StartDate, EndDate }) =>
              setCondition((dt) => ({ ...dt, StartDate, EndDate }))
            }
            type="year"
          />
        </div>
      </div>
      <div className="card-body card-body-cd p-2">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={380}
            height={300}
            data={sortBy(data, "Date")}
            margin={{
              top: 5,
              right: 5,
              left: 6,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="TotalDeductions"
              stackId="a"
              fill={StandardConst.Colors.info}
            />
            <Bar
              dataKey="NetSalary"
              stackId="a"
              fill={StandardConst.Colors.success}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

export const ContactUs = () => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [contactUs, setContactUs] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Officelocation/getContactForOfficeLocation`,
    }).then((r) => {
      setContactUs(r);
    });
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData()]);
  }, [loggedUser]);
  return (
    <>
      {contactUs?.length > 0 && (
        <>
          <div className="card user-card">
            <div className="bg-primary d-flex justify-content-center text-white h6 p-2 card-header mb-3">
              Contact Us
            </div>

            <Carousel  
              nextIcon={<ChevronRightIcon />}
              prevIcon={<KeyboardArrowLeftIcon />}
            >
              {contactUs.map((contactDetail, contactIndex) => (
                <Carousel.Item key={contactIndex}>
                  <div className="card-block">
                    <div className="user-image d-flex justify-content-center">
                      <Avatar
                        alt={contactDetail?.EmployeeDetail?.FullName}
                        src={`${StandardConst.uploadImages}${
                          contactDetail?.EmployeeDetail?.ProfileImage ?? ""
                        }`}
                        sx={{ width: 60, height: 60, border: "2px solid lightgray" }}
                      />
                    </div>

                    <h6 className="f-w-600">{contactDetail?.ContactPersonName}</h6>
                    <p className="text-muted m-0">{contactDetail?.ContactType}</p>
                    <br />
                    <div className="col-auto user-social-link m-t-10">
                      <a href={`tel:${contactDetail?.ContactNumbers}`}>
                        <i className="fa fa-phone  text-facebook"></i>
                      </a> 
                    </div>
                    {contactDetail?.ContactNumbers}
                    {(contactDetail?.EmployeeDetail?.Email) && (
                      <>
                        <div className="col-auto user-social-link m-t-10">
                          <a href={`mailto:${contactDetail?.EmployeeDetail?.Email}`}>
                            <i className="fa fa-envelope text-twitter"></i>
                          </a> 
                        </div> {contactDetail?.EmployeeDetail?.Email}
                      </>
                    )}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export const EmployeeOnLeave = () => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [EmployeeHoliday, setEmployeeHoliday] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/CurrentLeave?timeZone=${DateTime.now().zone.name}`,
    }).then((r) => {
      r = map(r, (m) => {
        if (m.LeaveFromDate === m.LeaveToDate) m.Leaves = "Today";
        else
          m.Leaves = `${DateTime.fromISO(m.LeaveFromDate).toFormat(
            "MMM, dd"
          )} - ${DateTime.fromISO(m.LeaveToDate).toFormat("MMM, dd")}`;
        m.ProfileImage = `${StandardConst.uploadImages}${m.ProfileImage}`;
        return m;
      });
      setEmployeeHoliday(r);
    });
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData()]);
  }, [loggedUser]);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-3 mt-3">
        <h5 className="card-title h6">Employee(s) on Leave</h5>
      </div>

      <div className="card-body card-body-cd card-employee-holyday">
        {EmployeeHoliday.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>None of Our Employee on Leave</b>
              </span>
            </div>
          </>
        )}
        {EmployeeHoliday.map((row, key) => (
          <div className="d-flex flex-column" key={key}>
            <div className="row py-1">
              <div className="col-2">
                <Avatar
                  alt={row?.EmployeeName}
                  src={row?.ProfileImage}
                  sx={{ width: 40, height: 40 }}
                />
              </div>
              <div className="col-7">
                <span className="h6 font-size-13 m-0 text-center">
                  {row?.EmployeeName}
                </span>
                <br />
                <span className="text-muted h6 font-size-13 m-0 text-center">
                  {row?.Designation}
                </span>
              </div>
              <div className="col-3 p-0">
                <span className="font-size-13 py-2 pe-2">{row?.Leaves}</span>
              </div>

              {/* <div className="col-2">
                <span className="font-size-13 py-2 pe-2">{row?.Leaves}</span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CompanyProjects = memo(({ Self }) => {
  Self ??= false;
  // const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  const [rows, setRows] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/ProjectStatus?${generateQueryStringByObject({
        Self, // jab Employee spacific data dikhana hai to self dena hoga or default self false hai
      })}`,
    }).then((r) => {
      r = map(r.projects, (p) => ({
        ProjectName: p.ProjectName,
        Customer: p.Customer,
        Status: p.Status,
        Billable: where(r.teamMembers, {
          ProjectId: p.ProjectId,
          IsBillable: 1,
        }).length,
        NonBillable: where(r.teamMembers, {
          ProjectId: p.ProjectId,
          IsBillable: 0,
        }).length,
        Team: where(r.teamMembers, { ProjectId: p.ProjectId }).map((m) => ({
          EmployeeName: m.FullName,
          EmployeeImage:
            m.ProfileImage === null
              ? "a.png"
              : `${StandardConst.uploadImages}${m.ProfileImage}`,
        })),
      }));
      setRows(r);
    });
  };
  useEffect(() => {
    Promise.all([fetchData()]);
  }, []);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-6 d-flex justify-content-start">
          <h5 className="card-title">Project Status</h5>
        </div>
        <div className="col-6 d-flex justify-content-end">
          Total : {rows.length}
        </div>
      </div>

      <div className="card-body card-body-cd">
        {rows.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>No Projects Found</b>
              </span>
            </div>
          </>
        )}
        {rows.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell align="center">Customer</TableCell>
                  <TableCell align="center">Team</TableCell>
                  {/* <TableCell align="center">Duration</TableCell> */}
                  <TableCell align="center">Project Status</TableCell>
                  {/* <TableCell align="center">Billable</TableCell>
                  <TableCell align="center">Non-Billable</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell scope="row">
                      <span style={{cursor : "pointer"}} onClick={async () => { nevigate(`/Project?search=${row?.ProjectName}`);	}}>
                        {row.ProjectName}
                      </span>
                    </TableCell>
                    <TableCell align="center">{row.Customer}</TableCell>
                    <TableCell align="center">
                      <AvatarGroup max={5}>
                        {row.Team.map((child_row, key) => (
                            <Avatar
                              key={key}
                              sx={{ width: 30, height: 30 }}
                              alt={child_row.EmployeeName}
                              src={child_row.EmployeeImage}
                            />
                        ))}
                      </AvatarGroup>
                    </TableCell>
                    {/* <TableCell align="center">{row.Duration}</TableCell> */}

                    <TableCell align="center">{StandardConst.ProjectStatus.find(item => item.value === row?.Status)?.text}</TableCell>
                    {/* <TableCell align="center">{row.Billable}</TableCell>
                    <TableCell align="center">{row.NonBillable}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
});
export const ProfileSummary = ({ ...rest }) => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const fetchProfile = async () => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/employees?select=FullName, ProfileImage, fn_Designation(DesignationId) Designation&where=EmployeeId eq ${loggedUser}`,
    }).then((r) => setState(r.length > 0 ? r[0] : {}));
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchProfile()]);
  }, [loggedUser]);
  return (
    <div {...rest}>
      <div className="d-flex flex-column flex-lg-row flex-sm-row justify-content-center align-items-center ms-2">
        <Avatar
          alt={state.FullName}
          src={`${StandardConst.uploadImages}${
            state.ProfileImage ?? "user_placeholder.jpg"
          }`}
          sx={{ width: 40, height: 40 }}
        />
        <div className="row flex-fill">
          <div className="col-sm-12 ms-2 d-flex flex-column">
            <div>
              <span className="d-none d-md-inline h6">Welcome,</span>
              <span className="h6"> {state.FullName}</span>
            </div>
            <span className="text-muted font-size-13">{state.Designation}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export const AttendanceSummary = ({ ...rest }) => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [attendanceData, setAttendanceData] = useState({});

  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Attendance/DayWiseSummary?${generateQueryStringByObject({
        startTime: DateTime.now()
          .startOf("day")
          .setZone("utc")
          .toSQL()
          .substring(0, 23),
        endTime: DateTime.now()
          .endOf("day")
          .setZone("utc")
          .toSQL()
          .substring(0, 23),
        CompanyId: companyInfo.CompanyId,
      })}`,
    }).then(setAttendanceData);
  };
  
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo.CompanyId]);
  return (
    <>
      <div {...rest}>
        <div className="column flex-fill mx-1">
          <span className="text-success">
            Present:{" "}
            <span className="h6 text-success">{attendanceData.Attend ?? 0}</span>
          </span>
        </div>
        <div className="column flex-fill mx-1">
          <span className="text-warning h6">
            Leave: <span className="h6 text-warning">{attendanceData.Leaves ?? 0}</span>
          </span>
        </div>
        <div className="column flex-fill mx-1">
          <span className="text-danger">
            Absent: <span className="h6 text-danger">{attendanceData.Absent ?? 0}</span>
          </span>
        </div>
      </div>
    </>
  );
};

export const ConfigurationManager = ({ ...rest }) => {
  const afterRender = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configurationVisibility, setConfigurationVisibility] = useState([]);
  useEffect(() => {
    if (!afterRender.current) {
      Promise.all([
        WebService({ /*dispatch,*/ endPoint: "ConfigurationFlow" }).then(
          (c) => {
            setConfigurationVisibility(
              map(c.flow, (m) =>
                extend(m, {
                  component: where(c.component, {
                    ConfigurationId: m.ConfigurationId,
                  }),
                })
              )
            );
          }
        ),
      ]);
    }
    afterRender.current = true;
  }, []);
  const [progress, setProgress] = React.useState(50);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#50FBA2" : "#3C65C4",
    },
  }));
  const checkMark = <CheckCircleOutlineIcon sx={{ fontSize: 30 }} />;
  const incompleteCheckMark = (
    <Avatar sx={{ width: 30, height: 30 }}>{checkMark}</Avatar>
  );
  const completeCheckMark = (
    <Avatar sx={{ bgcolor: green[500], width: 30, height: 30 }}>
      {checkMark}
    </Avatar>
  );
  const completeNowButton = (component) => (
    <>
      <button
        onClick={() => {
          navigate(component.Nevigaion);
        }}
        type="button"
        className="btn btn-outline-primary btn-sm"
        style={{ width: '110px', height: '30px', whiteSpace: 'nowrap', paddingLeft: "8px", paddingRight: "8px" }}
      >
        Complete Now
      </button>
    </>
  );
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: 5,
    padding: theme.spacing(2, 0.8, 2, 0.8),
    "& .MuiChip-root": {},
  }));

  const completedButton = (component) => (
    <>
      <StyledChip
        icon={<DoneIcon />}
        label="Completed"
        color="success"
        size="small"
      />
    </>
  );
  const flow = (m) => (
    <div className="card card-cd mb-3 snippets bootdey">
      <div className="widget-head-color-box lazur-bg p-lg">
        <div className="row">
          <div className="col d-flex justify-content-start flex-column">
            <div className="font-bold no-margins">{m.ConfigurationName}</div>
            <div> {m.Details}</div>
          </div>
          <div className="col d-flex align-items-end justify-content-center flex-column me-3">
            <Box sx={{ width: "50%" }}>
              <div>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    (where(m.component, {
                      StatusId: StandardConst.Status.Completed,
                    }).length /
                      m.component.length) *
                    100
                  }
                />
                <div className="d-flex justify-content-end">
                  {
                    where(m.component, {
                      StatusId: StandardConst.Status.Completed,
                    }).length
                  }
                  /{m.component.length} Completed
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className="widget-text-box mt-n4">
        {map(m.component, (component, componentIndex) => (
          <div className="row mb-4" key={componentIndex}>
            <div className="col flex-row d-flex justify-content-start">
              {(component.StatusId ?? StandardConst.Status.Pending) ===
              StandardConst.Status.Pending
                ? incompleteCheckMark
                : completeCheckMark}
              <span className="mx-2">
                {componentIndex + 1}. {component.ComponentName}
              </span>
              <Chip
                label={
                  (component.IsRequired ?? StandardConst.YesNo.No) ===
                  StandardConst.YesNo.Yes
                    ? "Required"
                    : "Optional"
                }
                variant="outlined"
                size="small"
                color={
                  (component.IsRequired ?? StandardConst.YesNo.No) ===
                  StandardConst.YesNo.Yes
                    ? "error"
                    : "info"
                }
              />
            </div>
            <div className="col d-flex justify-content-end">
              {(component.StatusId ?? StandardConst.Status.Pending) ===
              StandardConst.Status.Pending
                ? completeNowButton(component)
                : completedButton()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <>
      {map(configurationVisibility, (m,i) => (
        <React.Fragment key={i}>
          {m.component.length !==
            where(m.component, { StatusId: StandardConst.Status.Completed })
              .length && flow(m)}
        </React.Fragment>
      ))}
    </>
  );
};

export const ConfigurationShowYellowBar = () => {
  const navigate = useNavigate();
  const [remainingItemsCount, setRemainingItemsCount] = useState(0);

  const GetItemCount = () => {
    WebService({
      endPoint: `CommonUtility/getCount/configuration_status?CompanyId=${StandardConst.YesNo.Yes}&StatusId=${StandardConst.Status.Pending}`,
      method: "GET",
    }).then((response) => setRemainingItemsCount(response));
  }
  
  useEffect(() => {
    GetItemCount();
  },[]);

  const CloseConfigurationYellowBar = () => {
    setRemainingItemsCount(0);
  }

  return (
    <>
      {(remainingItemsCount > 0) && (
        <div className="card card-cd mb-3 mt-3 bg-warning">
          <div className="card-body p-0 p-2">
            <div className="d-flex flex-column flex-lg-row flex-sm-row">
              <div className="col-11">
                There are {remainingItemsCount} actions pending. <a onClick={() => navigate("/ActionCenter")} class="pe-auto text-primary">Go to Action Center</a>
              </div>
              <div className="col-1 pe-auto text-end">
                <CloseIcon style={{cursor : "pointer"}} onClick={() => CloseConfigurationYellowBar()}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export const SystemMaintenanceShowYellowBar = () => {

  const [remainingItemsCount, setRemainingItemsCount] = useState(0);
  const dispatch = useDispatch();
  const [AllSystemNotifications, setAllSystemNotifications] = useState([]);

  const fetchNotReadedNotification = async () => {
    const data = await WebService({
      dispatch,
      endPoint: `Notification?Skip=0&Take=500`
    }).then(r => r.filter(item => item.IsReaded === false && item.TriggerId === null ));
    setRemainingItemsCount(data.length);
    setAllSystemNotifications(data.slice(0,5));
  };
  useEffect(() => {
    fetchNotReadedNotification();
  }, [remainingItemsCount]);

  const CloseSystemMaintenanceYellowBar = (removedIndex) => {
    const filteredAllSystemNotifications = AllSystemNotifications.filter((r, index) => index !== removedIndex ?? []);
    setAllSystemNotifications(filteredAllSystemNotifications);
  }

  const fnRead = async (dr) => {
    await WebService({
      dispatch,
      endPoint: `Notification/MarkRead/${dr.NotificationId}`,
      method: "PUT",
      requiredLoader: false,
    })
    await fetchNotReadedNotification();
  };

  return (
    <>
      {(remainingItemsCount > 0) && (
        AllSystemNotifications.map((res, index) => (
          <React.Fragment key={index}>
            <div className="card card-cd mb-3 mt-3 bg-warning">
              <div className="card-body p-0 p-2">
                <div className="d-flex flex-lg-row flex-sm-row">
                  <div className="col-11">
                    <span>
                    {res.Message}
                    </span>
                    <ReactTimeAgo
                      date={res.CreatedOn}
                      locale="en-US"
                      className="font-size-13 text-wrap ms-2"
                    />
                    <MuiButton
                    color="primary"
                    size="small"
                    onClick={() => fnRead(res)}
                  >
                    Read
                  </MuiButton>
                  </div>
                  <div className="col-1 pe-auto text-end">
                    <CloseIcon className="cursor-pointer" onClick={() => CloseSystemMaintenanceYellowBar(index)} />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))
      )}
    </>
  )
};



export default CompanyDashboard;
