import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditKRA from "./AddEditKRA";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation } from "react-router-dom";

const apiUrl = "CommonUtility/statickra";
const KeyRating = () => {
  const { state } = useLocation();
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage KRA List" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Key Rating - Add"),
      ManageEdit: ActionPermission("Key Rating - Edit"),
      ManageDelete: ActionPermission("Key Rating - Delete"),
    });
  }, [permissionList]);

  const fetchKeyRating = async () => {
    const data = await WebService({ endPoint: apiUrl, dispatch });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (KRAId) => {
    await WebService({
      endPoint: `${apiUrl}?KRAId=${KRAId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchKeyRating();
  };

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchKeyRating();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Short Description",
      Value: "KRAShortDescription",
      cssClass: "text-left td-width-1",
    },
    {
      Text: "Description",
      Value: "KRADescription",
      cssClass: "text-left td-width-4",
    },
    {
      Text: "Action",
      key: "KRAId",
      cssClass: "text-center td-width-80",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.closest("[data-key]")?.getAttribute("data-key"))
            }
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnKRAEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                e.currentTarget.closest("[data-key]")?.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnKRADelete"
          />
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Performance Management",
      hrefLink: "#",
    },
    {
      title: "Manage KRA List",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage KRA List";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = " Key Rating";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageKRAheaderValueNoResults}
            subHeaderValue={StandardConst.ManageKRASubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddKeyRating" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditKRA
        callBackEvent={() => fetchKeyRating()}
        ref={addEditModalRef}
      ></AddEditKRA>
    </>
  );
};
export default KeyRating;
