import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditDocumentType from "./AddEditDocumentType";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { useLocation } from "react-router-dom";

const DocumentType = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Employee Document List" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const { state } = useLocation();

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Document - Add"),
    ManageEdit: ActionPermission("Document - Edit"),
    ManageDelete: ActionPermission("Document - Delete"),
    });
  }, [permissionList]);
  const fetchDocumentType = async () => {
    const data = await WebService({
      endPoint: `CommonUtility/fetchAll/staticdocumenttypes?CompanyId=${StandardConst.YesNo.Yes}`,
      dispatch,
    });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };
  const onDelete = async (DocumentTypeId) => {
    await WebService({
      endPoint: `CommonUtility/staticdocumenttypes?DocumentTypeId=${DocumentTypeId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchDocumentType();
  };

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchDocumentType();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Document Name",
      Value: "DocumentType",
    },
    // {
    //   Text: "Status",
    //   Value: "Status",
    // },
    {
      Text: "Mandatory",
      Value: "Mandatory",
    },
    {
      Text: "Action",
      key: "DocumentTypeId",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.closest("[data-key]")?.getAttribute("data-key"))
            }
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnDocumentTypeEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure ", // Text if Alert
                "Do you want  delete this document?",
                e.currentTarget.closest("[data-key]")?.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnDocumentTypeDelete"
          />
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Employee Document List",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Employee Document List";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Document Name";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""} // give sorce page path in this parameter when you use this component the back button will back you to the source page
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.DocumentListheaderValueNoResults}
            subHeaderValue={StandardConst.DocumentListHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditDocumentType
        callBackEvent={() => fetchDocumentType()}
        ref={addEditModalRef}
      ></AddEditDocumentType>
    </>
  );
};
export default DocumentType;
