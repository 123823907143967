import React, { useEffect, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "../Static/Static.css";
import AddEditARTypeMaster from "./AddEditARTypeMaster";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch } from "react-redux";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import DeleteConfirmAlert from "../../Services/AlertComponent";
const ARTypeMasterList = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "AR Type" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();

  //   const [permission] = useState({
  //     ManageAdd: ActionPermission("AR Type - Add"),
  //     ManageEdit: ActionPermission("AR Type - Edit"),
  //     ManageDelete: ActionPermission("AR Type - Delete"),
  //   });

  const fetchARTypeMasterList = async () => {
    const data = await WebService({ endPoint: "ARTypeMaster/Fetch", dispatch });
    setRecords(data);
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchARTypeMasterList();
    }
    renderAfterCalled.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  const columns = [
    {
      Text: "Code",
      Value: "Code",
    },
    {
      Text: "Salary Component",
      Value: "EarningOrDeductionName",
    },
    {
      Text: "Display Description",
      Value: "DisplayDescription",
    },
    {
      Text: "Action",
      key: "ARTypeId",
      cssClass: "text-center td-width-100",
      // isVisiable: permission.ManageEdit,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.closest("[data-key]")?.getAttribute("data-key"))
            }
            // disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnErrorEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                e.currentTarget.closest("[data-key]")?.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            IconName="Delete"
            id="btnBankDetailsDelete"
          />
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Master",
      hrefLink: "#",
    },
    {
      title: "AR Type",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "AR Type";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const onDelete = async (FinanceId) => {
    await WebService({
      endPoint: `ARTypeMaster/Remove/${FinanceId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    fetchARTypeMasterList();
  };
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                  Add {MasterPageName}
                </Button>
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        //IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditARTypeMaster
        callBackEvent={() => fetchARTypeMasterList()}
        ref={addEditModalRef}
      ></AddEditARTypeMaster>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
    </>
  );
};

export default ARTypeMasterList;
