import React, { useRef, useState } from "react";
import QrReader from 'react-qr-scanner';
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { WebService } from "../../Services/WebService";
import {
  Card,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { PageInfo } from "../PageInfo";
import ActionButton from "../../Services/ActionButton";
import { format } from "date-fns/esm";
import AlertComponent from "../../Services/AlertComponent";

const EmployeeAttendanceWithQRCodeScanning = () => {
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Employee Attendance By QR-Code" });
  // "environment" dene per by default back camera open hota hai or front camera open krne ke liye "user" dena hota hai
  const [openFrontOrBackCamera , setOpenFrontOrBackCamera] = useState(StandardConst.Camera5BackCamera);
  
  const MasterPageName = "Employee Attendance By QR-Code";
  const [bData, setBData] = React.useState([
    {
      title: "Home",
      hrefLink: "/",
      visible: false,
    },
    {
      title: "Employee Attendance By QR-Code",
      hrefLink: "#",
    },
  ]);


  // This code is qr scanner
  const [key, setKey] = useState(0);

  const handleScan = (data) => {
    if (data) {
      setKey((prevKey) => prevKey + 1);
      CheckInEmployeeByQRCode(data.text);
    }
  }

  const handleError = (err) => {
    console.log(err);
  }

  const alertRef = useRef(null);

  const getLetitudeAndLongitude = async (checkInCheckOutText, scannedQRData) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // You can send this data to your API here
          checkInCheckOutEmployeeByQRCode({ Latitude : latitude, Longitude : longitude, scannedQRData })
        },
        (error) => {
          if (error.code === 1) {
            alertRef.current.confirmAlert(
              `Ignore Location and proceed with ${checkInCheckOutText}`, //Confirm button text
              "Location access is not enabled.", // Text if Alert
              `Please allow location access and then retry ${checkInCheckOutText}. Otherwise click IGNORE if you would like to ${checkInCheckOutText} without location data.`, // Message of Alert
              {scannedQRData} 
            )
          } else if (error.code === 2) {
            alertRef.current.confirmAlert(
              `Ignore Location and proceed with ${checkInCheckOutText}`, //Confirm button text
              "Location unavailable !", // Text if Alert
              `Please check your location settings and then retry ${checkInCheckOutText}. Otherwise click IGNORE if you would like to ${checkInCheckOutText} without location data.`, // Message of Alert
              {scannedQRData} 
            )
          } else if (error.code === 3) {
            alertRef.current.confirmAlert(
              `Ignore Location and proceed with ${checkInCheckOutText}`, //Confirm button text
              "Location request timed out !", // Text if Alert
              `Please retry ${checkInCheckOutText}. Otherwise click IGNORE if you would like to ${checkInCheckOutText} without location data.`, // Message of Alert
              {scannedQRData} 
            )
          } else {
            alertRef.current.confirmAlert(
              `Ignore Location and proceed with ${checkInCheckOutText}`, //Confirm button text
              "An unknown error occurred !", // Text if Alert
              `Please retry ${checkInCheckOutText}, Otherwise, click IGNORE if you would like to ${checkInCheckOutText} without location data.`, // Message of Alert
              {scannedQRData} 
            )
          }
        }
      );
    } else {
      alertRef.current.confirmAlert(
        `Ignore Location and proceed with ${checkInCheckOutText}`, //Confirm button text
        "Geolocation is not supported by your browser !", // Text if Alert
        `Click IGNORE if you would like to ${checkInCheckOutText} without location data.`, // Message of Alert
        {scannedQRData} 
      )
    }
  };

  // scannedQRData me qr code me data ayega - employee hai to "E,{Guid}"
  const CheckInEmployeeByQRCode = async (scannedQRData) => {
    getLetitudeAndLongitude("Check-In Check-Out", scannedQRData)
  }
  
  const checkInCheckOutEmployeeByQRCode = async (locationData) => {
    locationData ??= {};
    const scannedQRData = locationData.scannedQRData;
    let showTheErrorMsg = true;
    const arrQRCodeData = scannedQRData.split(',');
    if(scannedQRData !== "" && arrQRCodeData.length > 0) {
      const Employee = arrQRCodeData[0];
      const Guid = arrQRCodeData[1];

      // this is for Employee Id Card -- Guid ki length 36 hoti hai to humne check kr liye ki agar 32 se jada hai to iska mtlab hai ki hamara hi qr code hai
        if(Employee === StandardConst.UserType.Employee && Guid.length > 32) {

            const TodayDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");

            const objBody = {TodayDateTime: TodayDateTime, Guid: Guid};
            if(locationData.Latitude != undefined && locationData.Longitude != undefined){
              objBody.Latitude = locationData.Latitude;
              objBody.Longitude = locationData.Longitude;
            }
            await WebService({
                dispatch,
                endPoint: "EmployeeIdCard/EmployeeCheckInByQRCode",
                body: objBody,
                method: "POST",
            }).then((res) => {
                showTheErrorMsg = false;
                if(res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.CheckInMsg){
                    WSSuccessAlert("success", res.EmployeeName+" Employee Sucessfully Checked In.");
                }else if(res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.CheckOutMsg){
                    WSSuccessAlert("success", res.EmployeeName+" Employee Sucessfully Checked Out.");
                }else if(res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.EmployeeErrMsg){
                    WSErrorAlert("Error", "Not a valid employee please contact your HR.");
                }else{
                    WSErrorAlert("Error", "Something went wrong please contact your admin.");
                }
            });
        }
    }

    if(showTheErrorMsg){
      WSErrorAlert("Invalid Badge", "This badge is not a valid badge. Please contact reception desk.");
    }

  };

  const toggleCamera = () => {
    if(openFrontOrBackCamera === StandardConst.Camera.BackCamera){
      setOpenFrontOrBackCamera(StandardConst.Camera.FrontCamera);
    }else{
      setOpenFrontOrBackCamera(StandardConst.Camera.BackCamera);
    }
  }


  return (
    <>
      <AlertComponent
        ref={alertRef}
        confirmEvent={(v) => checkInCheckOutEmployeeByQRCode(v)}
      />
      <div className="base-container container-fluid ">
        <Box
          sx={{
            pt:1,
            width: 1,
            height: 80,
          }}
        >
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        <div className="p-3 primary-bg-color"></div>

        <div class="base-margin-x base-margin-2y mb-2 ">
          <div className="row mb-4 justify-content-center mt-2">
            <Card sx={{ maxWidth: 300, backgroundColor: "#fbfbfb" }}>
              <div className="text-center">
                  <QrReader
                    key={key}
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    constraints={{
                      video: { facingMode: openFrontOrBackCamera }
                    }}
                    style={{ width: '100%' }}
                  />
                  <span>
                    Scan the QR CODE of the badge  
                    <ActionButton
                      onClick={() => toggleCamera()}
                      IconName="SwitchCameraLarge"
                      IconTooltip="Switch Camera"
                      id="SwitchCamera"
                    />
                    <ActionButton
                      IconName="FullScreenLarge"
                      IconTooltip="Full Screen"
                      id="FullScreen"
                    />
                    <ActionButton
                      IconName="FullScreenExitLarge"
                      IconTooltip="Exit Full Screen"
                      id="FullScreenExit"
                    />
                  </span>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeAttendanceWithQRCodeScanning;
