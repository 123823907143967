import React, { useEffect, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditLeave from "./AddEditleave";
import ApproveRejectLeave from "./ApproveDecline";
import Container from "react-bootstrap/Container";
import "../Static/Static.css";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../Services/TableComponent";
import { PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import Button from "react-bootstrap/Button";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { DateTime } from "luxon";
import { chain, map } from "underscore";
import { useSearchParams } from "react-router-dom";
import ActionButton from "../../Services/ActionButton";

const empid = 0;
const AllApplyLeaveList = () => {
  const [searchParams] = useSearchParams();
  const ref = useRef();
  const refSnackbar = useRef();
  const [records, setRecords] = useState([]);
  const [ApprovalStatus, setleaveApprovalStatus] = useState([]);
  const [Employeeleave, setEmployeeleave] = useState([]);
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Apply Leaves" });

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: true, // ActionPermission("Leave - Add"),
      ManageEdit: true, // ActionPermission("Leave - Edit"),
      ManageAllUser: true, // ActionPermission("Leave - Search All User"),
      ManageAprroveReject: true, // ActionPermission("Leave - Approve Reject"),
    });
  }, [permissionList]);

  const fetchLeaveList = async () => {
    var endPoint = "ApplyLeave/Fetch/Own";
    const data = await WebService({ endPoint, dispatch });
    setRecords(
      map(data?.AllLeave, (m) => ({ ...m, EncodedId: window.btoa(m.LeavesId) }))
    );
    setEmployeeleave(data.Employeeleave);
    setleaveApprovalStatus(data?.AllLeave?.ApprovalStatusId);
  };
  const onDelete = async (DocumentTypeId) => {
    await fetchLeaveList();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const approveRejectModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchLeaveList();
    }
    renderAfterCalled.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  const viewLeaveDetails = async (id) =>
    await approveRejectModalRef.current.openModal(id || 0, true);

  const fnCancel = async (id) => {
    const data = {
      LeavesId: id,
      StatusId: StandardConst.Status.Cancel,
    };
    await WebService({
      endPoint: "Applyleave/ChangeLeaveStatus",
      body: data,
      dispatch,
    });
    fetchLeaveList();
  };
  const cancelActionVisiable = (application) => {
    return (
      application.StatusId === StandardConst.Status.Applied ||
      (application.StatusId === StandardConst.Status.Approved &&
        DateTime.fromISO(application.LeaveFromDate) >= DateTime.now())
    );
  };
  const columns = [
    { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
    { Text: "From Date", DateFormat: "yyyy-MM-dd", Value: "LeaveFromDate" },
    { Text: "To Date", DateFormat: "yyyy-MM-dd", Value: "LeaveToDate" },
    { Text: "Leave Type", Value: "LeaveName" },
    { Text: "No. of Leave", Value: "NoOfLeaves" },
    { Text: "Apply Date", DateFormat: "yyyy-MM-dd", Value: "LeaveApplyDate" },
    { Text: "Approval Note", Value: "ApprovalNote" },
    //{ Text: "Approval Status", Value: "approvalStatus" },
    { Text: "Status", Value: "Status" },
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      render: (dr) => (
        <span className="d-flex justify-align-items-center align-items-lg-center">
           <ActionButton
            sx={{ color: "#9A9A9A" }}
            onClick={async () => await viewLeaveDetails(dr.LeavesId)}
            IconName="View"
            id="btnView"
          />
          {cancelActionVisiable(dr) && (
            <button
              className="btn btn-default mx-4"
              id="btnleaveApplyCancel"
              onClick={() => fnCancel(dr.LeavesId)}
            >
              <i className="fa fa-close"></i>
            </button>
          )}
         
        </span>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Leave Management",
      hrefLink: "#",
    },
    {
      title: "Apply Leaves",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Apply Leaves";
  const confirmMessage = MasterPageName + "Deleted successfully";
  const AddButtonText = "Leave";
  useEffect(() => {
    if (
      searchParams.get("action") === "ViewDetails" &&
      searchParams.get("search") !== null
    ) {
      const LeaveId = parseInt(window.atob(searchParams.get("search")));
      Promise.all([viewLeaveDetails(LeaveId)]);
    }
  }, []);

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />

      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
            height: 80,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h3 className="ms-4 mt-2">{MasterPageName}</h3>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
            <div className="me-2">
              <Row>
                <Col>
                  <div>
                    {Employeeleave.map((data, key) => {
                      return (
                        <span className="badge rounded-pill text-bg-primary mx-1" key={key}>
                          <span>{data.LeaveName} : </span>
                          <span>
                            {data.Usedleave}
                            {data.Balance > 0 && <>/{data.Balance}</>}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Box>
        <TableComponent
          columns={columns}
          data={chain(records)
            .sortBy((s) => s.LeavesId)
            .reverse()
            .value()}
          title={AddButtonText}
          isSearchRequired={true}
          noRecordCss="p-0"
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.ApplyLeavesheaderValueNoResults}
              subHeaderValue={StandardConst.ApplyLeavesSubHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
              actionButton={
                <>
                  {permission.ManageAdd && (
                    <Button id="NoRecordFoundApplyLeave" variant="outline-primary" onClick={() => fnEdit(0)}>
                      Apply {AddButtonText}
                    </Button>
                  )}
                </>
              }
            />
          }
          onAddEvent={() => fnEdit()}
          IsAddButtonVisible={permission?.ManageAdd}
          allowSerialNo={true}
          initialSearchContent={searchParams.get("search") ?? ""}
        />
        <AddEditLeave
          callBackEvent={() => fetchLeaveList()}
          ref={addEditModalRef}
        ></AddEditLeave>

        <ApproveRejectLeave
          // callBackEvent={() => fetchLeaveList()}
          ref={approveRejectModalRef}
        ></ApproveRejectLeave>
      </Container>
    </>
  );
};

export default AllApplyLeaveList;
