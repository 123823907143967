import React from "react";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { InputText } from "../Form";
import { useDispatch } from "react-redux";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import ActionButton from "../../Services/ActionButton";

const { forwardRef, useState, useImperativeHandle } = React;

const LoginByCompanyName = ({ origin }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [searchContent, setSearchContent] = useState("");
    const [searchedCompanyData, setSearchedCompanyData] = useState([]);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
        setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setSearchedCompanyData([]);
    };

    const searchCompany = async () => {
        if(searchContent){
            await WebService({
                endPoint: `CompanyProfile/FetchCompanyByName?SearchContent=${searchContent}`,
                method: "GET",
                dispatch,
            }).then(res => res.length > 0 ? setSearchedCompanyData(res): setSearchedCompanyData([]));
        }else{
            setSearchedCompanyData([]);
        }
    }

    var columns = [
        {
            Text: "Company Name",
            Value: "CompanyName",
        },
        {
            Text: "",
            render: (dr) => (
                <div className="d-flex justify-content-end">
                    <a href={`${origin}/#/Login?company=${dr.Code}`}>
                        <button
                            className="btn btn-primary"
                            id="loginbtn"
                        >
                            Login&nbsp;{">>"}
                        </button>
                    </a>
                </div>
            ),
          },
    ]

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Search your company name and login here</Modal.Title>
            </Modal.Header>

            <div className="p-2">
                <div className="row">
                <div className="col-12">
  <div className="position-relative">
    <InputText
      className="form-control pr-2"
      style={{ paddingRight: "45px" }}
      placeholder="Company Name"
      setValue={(val) => setSearchContent(val)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          searchCompany(); // Trigger the search function when Enter is pressed
        }
      }}
    />
    <ActionButton
      className="position-absolute"
      style={{ top: "0px", right: "0px" }}
      onClick={() => searchCompany()}
      IconName="Search"
      id={`btnSearch`}
    />
  </div>
</div>

                </div>
                {searchedCompanyData.length > 0 ? (
                    <TableComponent 
                        columns={columns} 
                        data={searchedCompanyData}
                        IsAddButtonVisible={false}
                        isExcelExportButtonVisible={false}
                        isSearchRequired={false}
                    />
                ) : (
                    <NoRecordTemplate
                        headerValue={"Search Your Company"}
                        // subHeaderValue={StandardConst.AccessLevelTypesSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                    />
                )}
            </div>
        </Modal>
    );
};

export default forwardRef(LoginByCompanyName);
